import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Guest } from '@/entities/Guest'
const requestService = new RequestService()

export class GuestRequests {
  get (id) {
    const route = ServerDirections.GUESTS_ID.replace('{id}', id)
    const method = 'get'
    function map (data) {
      return new Guest(data)
    }
    return requestService.makePromise(route, method, {}, map)
  }

  post (guest) {
    const route = ServerDirections.GUESTS
    const method = 'post'
    function map (data) {
      return data.id
    }
    return requestService.makePromise(route, method, { body: guest }, map)
  }

  unassignWirstBand (id) {
    const route = ServerDirections.POS_GUESTS_ID_KEY.replace('{id}', id)
    const method = 'delete'
    return requestService.makePromise(route, method, {}, () => {})
  }
}
