import { CurrencyRequests } from '@/services/currency.requests'
const currencyService = new CurrencyRequests()

const state = {
  list: []
}

const actions = {
  list ({ state }) {
    return new Promise((resolve, reject) => {
      currencyService.list().then((currencies) => {
        state.list = currencies
        resolve(currencies)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions
}
