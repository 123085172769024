import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { ExtendedAccount } from '@/entities/ExtendedAccount'
const requestService = new RequestService()

export class AccountRequests {
  get (id) {
    const route = ServerDirections.ACCOUNTS_ID.replace('{id}', id)
    const method = 'get'
    function map (data) {
      return new ExtendedAccount(data)
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
