import { ServiceRequests } from '@/services/service.requests'
const serviceService = new ServiceRequests()

const state = {
  list: []
}
const getters = {
}

const actions = {
  list ({ state, rootGetters }, { decorate }) {
    return new Promise((resolve, reject) => {
      serviceService.list().then((services) => {
        if (decorate) {
          const facilities = rootGetters['auth/allowedFacilities']
          services = services.map((service) => {
            const facility = facilities.find((facility) => { return facility.id === service.facilityId })
            service.facility = facility || null
            return service
          })
        }
        state.list = services
        resolve(services)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
