import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Order } from '@/entities/Order'
import { Transaction } from '@/entities/Transaction'
import snakeCaseKeys from 'snakecase-keys'
import store from '@/store'
const requestService = new RequestService()

export class OrderRequests {
  list (limit, offset, order, since, until, stationId, accountIds, orderIds) {
    const route = ServerDirections.POS_ORDERS
    const method = 'get'
    var params = { limit, offset }
    if (stationId) {
      params.station_id = stationId
    }
    if (order) {
      params.order = order
    }
    if (since) {
      params.since = since
    }
    if (until) {
      params.until = until
    }
    if (status) {
      params.status = status.toString() || []
    }
    if (accountIds) {
      params.accountIds = accountIds.toString() || []
    }
    if (orderIds) {
      params.orderIds = orderIds.toString() || []
    }
    params = snakeCaseKeys(params)
    const map = (res) => {
      return {
        list: res.data.map(item => {
          return new Order(item)
        }),
        meta: {
          hasNext: res.meta.has_next
        }
      }
    }
    return requestService.makePromise(route, method, { params }, map)
  }

  listTransactions (orderId) {
    const route = ServerDirections.ORDER_ID_TRANSACTIONS.replace('{id}', orderId)
    const method = 'get'
    const map = (data) => {
      return data.map(item => {
        return new Transaction(item)
      })
    }
    return requestService.makePromise(route, method, {}, map)
  }

  refund (order) {
    const { id, reference, stationId } = order
    const route = ServerDirections.POS_ORDERS_ID_REFUND.replace('{id}', id)
    const method = 'post'
    const body = snakeCaseKeys({
      reference,
      stationId,
      operatorId: store.getters['auth/operatorId']
    })
    const map = (data) => {
      return data.id
    }
    return requestService.makePromise(route, method, { body, useTemporalToken: !store.getters['shifts/isSupervisor'] }, map)
  }

  listRefunds (id) {
    const route = ServerDirections.ORDERS_ID_REFUNDS.replace('{id}', id)
    const method = 'get'
    const map = (data) => {
      return data.map(item => {
        return new Order(item)
      })
    }
    return requestService.makePromise(route, method, { }, map)
  }

  getByReference (reference) {
    const route = ServerDirections.POS_ORDERS_REFERENCE.replace('{reference}', reference)
    const method = 'get'
    const map = (data) => {
      return new Order(data)
    }
    return requestService.makePromise(route, method, { }, map)
  }

  get (id) {
    const route = ServerDirections.ORDERS_ID.replace('{id}', id)
    const method = 'get'
    const map = (data) => {
      return new Order(data)
    }
    return requestService.makePromise(route, method, { }, map)
  }

  getTicket (id, passId) {
    const route = passId
      ? ServerDirections.POS_PASSES_ID_ORDER_ID_TICKET.replace('{orderId}', id).replace('{passId}', passId)
      : ServerDirections.POS_ORDERS_ID_TICKET.replace('{id}', id)
    const method = 'get'
    function map (data) {
      var bytes = new Uint8Array(data)
      var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '') // eslint-disable-line
      return btoa(binary)
    }
    return requestService.makePromise(route, method, { responseType: 'arraybuffer' }, map)
  }

  getRefundTicket (orderRefundId) {
    const route = ServerDirections.POS_ORDER_REFUNDS_ID_TICKET.replace('{id}', orderRefundId)
    const method = 'get'
    function map (data) {
      var bytes = new Uint8Array(data)
      var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '') // eslint-disable-line
      return btoa(binary)
    }
    return requestService.makePromise(route, method, { responseType: 'arraybuffer' }, map)
  }
}
