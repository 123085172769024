import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Currency } from '@/entities/Currency'
const requestService = new RequestService()

export class CurrencyRequests {
  list () {
    const route = ServerDirections.CURRENCIES
    const method = 'get'
    function map (data) {
      return data.map(item => new Currency(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
