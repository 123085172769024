import Vue from 'vue'
const state = {
  read: null,
  edited: null,
  selectedStationId: localStorage.getItem('selectedStationId')
}
const getters = {
  isUnsaved: state => state.edited && !!Object.keys(state.edited).length,
  passwordExpiration: state => state.selectedStationId
}

const actions = {
  get ({ state }) {
    return new Promise((resolve, reject) => {
      var promises = []
      // get de default currency
      state.read = {}
      Promise.all(promises).then(() => {
        resolve(state.read)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  save ({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      var promises = []
      Promise.all(promises).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setRead (state, setting) {
    state.read = setting
  },
  setField (state, { field, value }) {
    if (!state.edited) {
      state.edited = {}
    }
    Vue.set(state.edited, field, value)
  },
  resetEdited (state) {
    state.edited = null
  },
  setSelectedStationId (state, id) {
    state.selectedStationId = id
    if (id) {
      localStorage.setItem('selectedStationId', id)
    } else {
      localStorage.removeItem('selectedStationId')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
