import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { PaymentMethod } from '@/entities/PaymentMethod'
const requestService = new RequestService()

export class PaymentMethodRequests {
  list () {
    const route = ServerDirections.PAYMENT_METHODS
    const method = 'get'
    function map (data) {
      return data.map(item => new PaymentMethod(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
