import { isGuestDisabled } from '@/utils/GuestUtils'
import { BookingRequests } from '@/services/booking.requests'
import { GuestRequests } from '@/services/guest.requests'
import dayjs from 'dayjs'
import Vue from 'vue'

const guestService = new GuestRequests()
const bookingService = new BookingRequests()

const state = {
  list: [],
  guests: [],
  read: null
}

const getters = {
  isBookingCancelled: (state) => { try { return state.guests.length && state.guests.every((guest) => isGuestDisabled(guest)) } catch { return false } },
  bookingPricingGroup: (state) => {
    try { return state.read.extendedAccount.account.pricingGroupId }
    catch { return null }
  }
}

const actions = {
  get ({ state, dispatch, rootGetters }, { id, save, decorate }) {
    return new Promise((resolve, reject) => {
      bookingService.get(id).then((booking) => {
        const promises = []
        if (decorate) {
          if (booking.accountId) {
            promises.push(
              dispatch('accounts/get', { id: booking.accountId }, { root: true }).then((extendedAccount) => booking.extendedAccount = extendedAccount),
              dispatch('orders/list', { accountIds: [booking.accountId], limit: 50, offset: 0 }, { root: true }).then((orders) => {
                booking.isOnlineTicketingBooking = orders.list.some(order => {
                  return order.serviceId === rootGetters['configuration/onlineTicketingServiceId']
                })
              })
            )
          }
        }
        Promise.all(promises).then(() => {
          if (save) {
            state.read = booking
          }
          resolve(booking)
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getGuests ({ state, dispatch }, { id, save, decorate }) {
    return new Promise((resolve, reject) => {
      bookingService.getBookingGuests(id).then(async (guests) => {
        if (decorate) {
          const promises = guests.map(g => dispatch('guests/get', { id: g.id, decorate: true }, { root: true }))
          Promise.all(promises).then(decoratedGuests => {
            if (save) {
              state.guests = decoratedGuests
            }
            resolve(decoratedGuests)
          }).catch((error) => {
            reject(error)
          })
        } else {
          if (save) {
            state.guests = guests
          }
          resolve(guests)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  },
  decorateGuestColumn ({ dispatch }, path) {
    switch (path) {
      case 'name':
        dispatch('decorateGuestProfile')
        break
    }
  },
  decorateGuestProfile ({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      const promises = state.guests.map(guest => 
        dispatch('profiles/get', { id: guest.profileId }, { root: true }).then((profile) => ({ ...guest, profile }))
      )
      Promise.all(promises).then((decoratedGuests) => {
        commit('setGuests', decoratedGuests)
        resolve(state.guests)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  disableAllGuests ({ state }) {
    return new Promise((resolve, reject) => {
      const promises = state.guests
        .filter(guest => !isGuestDisabled(guest))
        .map(guest => {
          return new Promise(async (resolve, reject) => {
            guestService.get(guest.id).then((guest) => {
              const body = guest.serialize({ checkoutMoment: dayjs().toISOString() })
              guestService.post(body).then(() => {
                resolve()
              }).catch((error) => {
                reject(error)
              })
            }).catch((error) => {
              reject(error)
            })
          })
        })
      Promise.all(promises).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setGuests (state, guests) {
    Vue.set(state, 'guests', guests)
  },
  resetRead (state) {
    Vue.set(state, 'read', null)
    Vue.set(state, 'guests', [])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
