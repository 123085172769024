import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Booking } from '@/entities/Booking'
import { Profile } from '@/entities/Profile'
import { Facility } from '@/entities/Facility'
import { BookingType } from '@/entities/BookingType'
import { Guest } from '@/entities/Guest'
import { Room } from '@/entities/Room'
const requestService = new RequestService()

export class BookingRequests {
  list ({ sort, order, facilityId, filter, limit, offset, onlyActiveBookings, checkin, checkout, typeId, locator }) {
    function map (data) {
      return {
        list: data.data.map(item => {
          return {
            booking: new Booking(item.booking),
            profile: new Profile(item.profile),
            bookingType: item.booking_type ? new BookingType(item.booking_type) : null,
            facility: new Facility(item.facility),
            rooms: item.rooms.map((room) => new Room(room)),
            guests: item.guests.map((g) => {
              return {
                guest: new Guest(g.guest),
                profile: new Profile(g.profile)
              }
            })
          }
        }),
        meta: {
          hasNext: data.meta.has_next
        }
      }
    }
    const route = ServerDirections.BOOKINGS
    const method = 'get'
    const params = {
      limit, offset
    }
    if (sort) {
      params.sort = sort
    }
    if (order) {
      params.order = order
    }
    if (facilityId) {
      params.facility_id = facilityId
    }
    if (filter) {
      params.filter = filter
    }
    if (checkin) {
      params.checkin = window.moment(checkin).format('YYYY-MM-DD')
    }
    if (checkout) {
      params.checkout = window.moment(checkout).format('YYYY-MM-DD')
    }
    if (locator) {
      params.locator = locator
    }
    params.only_active_bookings = onlyActiveBookings
    if (typeId) {
      params.type_id = typeId
    }
    return requestService.makePromise(route, method, { params }, map)
  }

  get (id) {
    const route = ServerDirections.BOOKINGS_ID.replace('{id}', id)
    const method = 'get'
    function map (data) {
      return new Booking(data)
    }
    return requestService.makePromise(route, method, {}, map)
  }

  getBookingGuests (bookingId) {
    function map (data) {
      return data.map((g) => new Guest(g))
    }
    const route = ServerDirections.BOOKINGS_ID_GUESTS.replace('{id}', bookingId)
    const method = 'get'
    return requestService.makePromise(route, method, {}, map)
  }
}
