import { TransactionRequests } from '@/services/transaction.requests'
import Vue from 'vue'

const transactionService = new TransactionRequests()

const state = {
  edited: {},
  list: []
}
const getters = {
  isUnsaved: state => state.edited && !!Object.keys(state.edited).length
}

const actions = {
  get ({ commit }, { id, save }) {
    return new Promise((resolve, reject) => {
      transactionService.get(id).then((transaction) => {
        if (save) {
          commit('setRead', transaction)
        }
        resolve(transaction)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  list ({ state, dispatch }, { save, decorate, limit, offset, accountIds, currencyIds, filter, order, guestId, stationId, operatorId, currencyType, reference, createdSince, createdUntil, type, paymentMethodIds, linkedToOrder }) {
    return new Promise((resolve, reject) => {
      transactionService.list({ limit, offset, accountIds, currencyIds, filter, order, guestId, stationId, operatorId, currencyType, reference, createdSince, createdUntil, type, paymentMethodIds, linkedToOrder }).then(async ({ list, meta }) => {
        if (decorate) {
          const promises = []
          list.forEach((transaction) => {
            if (transaction.relatedTransactionIds.length) {
              return transaction.relatedTransactionIds.forEach((relatedTransactionId) => {
                promises.push(
                  dispatch('get', { id: relatedTransactionId })
                )
              })
            }
          })
          await Promise.all(promises).then((relatedTransactions) => {
            list = list.map((transaction) => {
              return {
                ...transaction,
                relatedTransactions: transaction.relatedTransactionIds.map((relatedTransactionId) => {
                  return relatedTransactions.find((relatedTransaction) => relatedTransaction.id === relatedTransactionId)
                })
              }
            })
          }).catch((err) => {
            reject(err)
          })
        }
        if (save) {
          state.list = list
        }
        resolve({ list, meta })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  post ({ rootState, dispatch }, transactions) {
    return new Promise(async (resolve, reject) => {
      var stationId = localStorage.getItem('selectedStationId')
      if (!stationId && localStorage.getItem('selectedReader')) {
        await dispatch('peripherals/get', { id: localStorage.getItem('selectedReader') }, { root: true }).then((peripheral) => {
          stationId = peripheral.station ? peripheral.stationId : null
        })
      }
      const body = transactions.map((transaction) => {
        return {
          ...transaction,
          amount: (transaction.negativeTransaction ? -1 : 1) * transaction.amount,
          paymentMethodId: transaction.type === 'PAYMENT' ? transaction.paymentMethodId : null,
          operatorId: rootState.auth.credentials.operator.id,
          stationId
        }
      })
      transactionService.post({transactions: body}).then((ids) => {
        resolve(ids)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  decorateColumn ({ dispatch }, path) {
    switch (path) {
      case 'station.name':
        dispatch('decorateStations')
      case 'service.name':
        dispatch('decorateService')
      case 'order.reference':
        dispatch('decorateReference')
      case 'paymentMethod':
        dispatch('decoratePaymentMethod')
    }
  },
  decorateStations ({ state, dispatch }) {
    dispatch('stations/list', {}, { root: true }).then((stations) => {
      state.list = state.list.map((transaction) => {
        const station = stations.find(s => s.id === transaction.stationId)
        return { ...transaction, station }
      })
    })
  },
  decorateService ({ state, dispatch }) {
    dispatch('services/list', {}, { root: true }).then((services) => {
      state.list = state.list.map((transaction) => {
        const service = services.find(s => s.id === transaction.serviceId)
        return { ...transaction, service }
      })
    })
  },
  decorateReference ({ state, dispatch }) {
    const promises = state.list
      .filter((transaction) => transaction.orderId)
      .map((transaction) => {
        return transaction.orderId
        ? dispatch('orders/list', {
            limit: 1,
            offset: 0,
            orderIds: [transaction.orderId]
          }, { root: true }).then(res => {
            return res.list[0]
          })
        : null
      })
    Promise.all(promises).then((orders) => {
      state.list = state.list.map((transaction, i) => {
        return { ...transaction, order: orders.find(order => order.id === transaction.orderId) }
      })
    })
  },
  decoratePaymentMethod ({ state, dispatch }) {
    dispatch('paymentMethods/list', null, { root: true }).then((paymentMethods) => {
      state.list = state.list.map((transaction) => {
        const paymentMethod = paymentMethods.find(pM => pM.id === transaction.paymentMethodId)
        return { ...transaction, paymentMethod }
      })
    })
  },
}

const mutations = {
  setField (state, { field, value }) {
    if (!state.edited) {
      state.edited = {}
      if (state.read && state.read.id) {
        Vue.set(state.edited, 'id', state.read.id)
      }
    }
    Vue.set(state.edited, field, value)
  },
  resetEdited (state) {
    state.edited = {}
  },
  setEdited (state, edited) {
    state.edited = edited
  },
  setList (state, list) {
    state.list = list
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
