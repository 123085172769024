import store from '@/store'
import jwt from 'jsonwebtoken'
import dayjs from 'dayjs'
import { Http } from 'vue-resource'

export class RequestService {
  pendingPromises = {}
  authHeader () {
    return new Promise((resolve, reject) => {
      if (store.getters['auth/authStatus']) {
        const tokenInfo = jwt.decode(store.getters['auth/authToken'])
        const tokenExp = tokenInfo.exp * 1000
        if (dayjs(tokenExp).isBefore(dayjs())) {
          const refreshTokenInfo = jwt.decode(store.getters['auth/refreshToken'])
          if (dayjs(refreshTokenInfo.exp * 1000).isBefore(dayjs())) {
            store.dispatch('auth/signOut').catch(() => {})
            reject(new Error('Tokens expired'))
          } else {
            store.dispatch('auth/refreshToken').then((token) => {
              resolve(token)
            }).catch(() => {
            store.dispatch('auth/signOut').catch(() => {})
              reject(new Error('Failed token refresh'))
            })
          }
        } else {
          resolve(store.getters['auth/authToken'])
        }
      } else {
        store.dispatch('auth/signOut').catch(() => {})
        reject(new Error('No tokens'))
      }
    })
  }
  setPromise (route, method, body, params, promise, headers) {
    this.pendingPromises[route] = {
      promise,
      body,
      method,
      params,
      route,
      headers: JSON.stringify(headers)
    }
  }
  getPromise (route, method, body, params, headers) {
    const saved = this.pendingPromises[route]
    if (saved && typeof saved !== 'undefined' && saved.body === body && saved.method === method && saved.params === params && saved.headers === JSON.stringify(headers)) {
      return saved.promise
    } else {
      return null
    }
  }
  removePromise (route, method, body, params, headers) {
    const saved = this.pendingPromises[route]
    if (saved && saved.body === body && saved.method === method && saved.params === params && saved.headers === JSON.stringify(headers)) {
      this.pendingPromises[route] = null
    }
  }
  async makePromise (route, method, { body, params, noAuth, responseType, disableStatusHandling, useTemporalToken, keepTemporalToken }, mappingFunction) {
    var token
    var headers
    if (!noAuth) {
      if (useTemporalToken) {
        token = store.state.auth.temporalSupervisorToken
      }
      if (!token) {
        token = await this.authHeader().catch((error) => {
          return new Promise((resolve, reject) => {
            reject(error)
          })
        })
      }
      headers = { 'x-api-key': token }
    }
    const currentPromise = this.getPromise(route, method, body, params, headers)
    if (currentPromise) {
      return currentPromise
    }
    const promise = new Promise(async (resolve, reject) => {
      if (noAuth || (!noAuth && token)) {
        if (method === 'get' || method === 'delete') {
          Http[method](route, {
            headers: headers || {},
            params: params || null,
            responseType: responseType || null,
            disableStatusHandling
          }).then(
            data => {
              if (useTemporalToken && !keepTemporalToken) {
                store.commit('auth/removeTemporalSupervisorToken')
              }
              resolve(mappingFunction(data.body, data.headers))
            },
            error => {
              reject(error)
            }
          ).finally(() => {
            this.removePromise(route, method, body, params, headers)
          })
        } else {
          Http[method](route, body, {
            headers: headers || {},
            params: params || null,
            disableStatusHandling
          }).then(
            data => {
              resolve(mappingFunction(data.body, data.headers))
            },
            error => {
              reject(error)
            }
          ).finally(() => {
            this.removePromise(route, method, body, params, headers)
          })
        }
      }
    })
    this.setPromise(route, method, body, params, promise, headers)
    return promise
  }
}
