import Vue from 'vue'
import es from 'vuetify/lib/locale/es'
import en from 'vuetify/lib/locale/en'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import Vuetify, {
  VApp,
  VAppBar,
  VNavigationDrawer,
  VToolbar,
  VToolbarTitle,
  VContent,
  VIcon,
  VBtn,
  VList,
  VListGroup,
  VCard,
  VCardTitle,
  VCardActions,
  VCardText,
  VSpacer,
  VContainer,
  VSnackbar,
  VProgressLinear,
  VLayout,
  VFlex,
  VFadeTransition,
  VSlideXTransition,
  VSlideYTransition,
  VDataIterator,
  VDataTable,
  VDataTableHeader,
  VSimpleTable,
  VEditDialog,
  VTextField,
  VCheckbox,
  VMenu,
  VChip,
  VForm,
  VProgressCircular,
  VTooltip,
  VAutocomplete,
  VSelect,
  VSpeedDial,
  VExpansionPanels,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanel,
  VDivider,
  VDatePicker,
  VTabs,
  VTabsItems,
  VTabsSlider,
  VTabItem,
  VTab,
  VSwitch,
  VDialog,
  VTextarea,
  VTimePicker,
  VCombobox,
  VStepper,
  VStepperStep,
  VStepperContent,
  VImg,
  VOverlay,
  VRow,
  VCol,
  VBadge
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VNavigationDrawer,
    VToolbar,
    VToolbarTitle,
    VContent,
    VIcon,
    VBtn,
    VList,
    VListGroup,
    VCard,
    VCardTitle,
    VCardActions,
    VCardText,
    VSpacer,
    VContainer,
    VSnackbar,
    VProgressLinear,
    VLayout,
    VFlex,
    VFadeTransition,
    VSlideXTransition,
    VSlideYTransition,
    VDataIterator,
    VDataTable,
    VDataTableHeader,
    VSimpleTable,
    VEditDialog,
    VTextField,
    VCheckbox,
    VMenu,
    VChip,
    VForm,
    VProgressCircular,
    VTooltip,
    VAutocomplete,
    VSelect,
    VSpeedDial,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanel,
    VDivider,
    VDatePicker,
    VTabs,
    VTabsItems,
    VTabsSlider,
    VTabItem,
    VTab,
    VSwitch,
    VDialog,
    VTextarea,
    VTimePicker,
    VCombobox,
    VStepper,
    VStepperStep,
    VStepperContent,
    VImg,
    VOverlay,
    VRow,
    VCol,
    VBadge
  },
  directives: {
    Ripple
  }
})

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'xs'
  },
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#FF6700',
        secondary: '#9E918D',
        accent: '#561785',
        error: '#E53935',
        info: '#00BCE7',
        success: '#90C858',
        warning: '#FEBD3B'
      },
    },
  },
    lang: {
      locales: { en, es },
      current: (navigator.language || navigator.userLanguage).split('-')[0] === 'es' ? 'es' : 'en'
    },
    icons: {
      iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
});
