import { i18n } from '@/locale'
import store from '@/store'

function formatErrorString (errors) {
  const formErrors = {}
  errors.forEach(error => {
    formErrors[error.field] = []
    error.field_errors.forEach(fieldError => {
      const errorString = formatFieldError(
        fieldError,
        i18n.t('fields.' + error.field)
      )
      if (formErrors[error.field].indexOf(errorString) < 0) {
        formErrors[error.field].push(errorString)
      }
    })
  })
  return formErrors
}

function formatFieldError (fieldError, fieldName) {
  var errorString = ''
  if (fieldError.name === 'INVALID_OBJECT') {
    errorString = i18n.t('error.bad_request.' + fieldError.name, {
      field_name: fieldName
    })
    fieldError.value.forEach(value => {
      value.field_errors.forEach(valueFieldError => {
        errorString += formatFieldError(
          valueFieldError,
          i18n.t('fields.' + value.field)
        )
      })
    })
  } else if (fieldError.name === 'INVALID_ARRAY_ITEM') {
  } else if (fieldError.name === 'INVALID_FORMAT_ARRAY_ELEMENTS') {
    var errors = ''
    fieldError.value.forEach(valueFieldError => {
      errors +=
        formatFieldError(
          valueFieldError.error,
          i18n.t('error.bad_request.INDEX', {
            index: valueFieldError.index
          })
        ) + '\n    '
    })
    errorString += i18n.t('error.bad_request.' + fieldError.name, {
      field_name: fieldName.toLowerCase(),
      errors: errors
    })
  } else {
    errorString = i18n.t('error.bad_request.' + fieldError.name, {
      value: fieldError.value,
      field_name: fieldName
    })
  }
  return errorString
}

export class ErrorManager {
  manageError (error, disableStatusHandling) {
    if (!!disableStatusHandling && !!disableStatusHandling[error.status]) {
      return
    }
    switch (error.status) {
      case 400:
        store.commit('state/setFormErrors', formatErrorString(JSON.parse(error.body)))
        break
      case 401:
        const message = JSON.parse(error.body).message
        switch (message) {
          case 'You have been too long without logging in':
            store.commit('state/setMessage', { text: i18n.t('error.auth.long_inactivity_operator'), color: 'error' })
            break
          case 'Operator is not active':
            store.commit('state/setMessage', { text: i18n.t('error.auth.disabled_operator'), color: 'error' })
            break
          default:
            store.commit('state/setMessage', { text: i18n.t('error.auth.unauthorized'), color: 'error' })
        }
        store.dispatch('auth/signOut').catch(() => {})
        break
      case 403:
        if (JSON.parse(error.body).message === 'Operator is inactive.') {
          store.commit('state/setMessage', { text: i18n.t('error.auth.disabled_operator'), color: 'error' })
        } else {
          store.commit('state/setMessage', { text: JSON.parse(error.body).message, color: 'error' })
        }
        store.dispatch('auth/signOut').catch(() => {})
        break
      case 404:
        if (!error.url || !error.url.includes('defaults')) {
          store.commit('state/setMessage', { text: i18n.t('error.not_found'), color: 'error' })
        }
        break
      case 408:
        // store.commit('state/setMessage', { text: i18n.t('error.not_found'), color: 'error' })
        break
      case 409:
        const body = JSON.parse(error.body)
        switch (body.message) {
          case '{error=SHIFT_IS_ALREADY_CLOSED}':
            store.dispatch('shifts/reloadShifts')
            store.commit('state/setMessage', { text: i18n.t('shifts.close_shift.error.SHIFT_IS_ALREADY_CLOSED'), color: 'error' })
            break
          case '{error=NO_OPEN_SHIFT}':
            store.dispatch('shifts/reloadShifts')
            store.commit('state/setMessage', { text: i18n.t('error.conflict.NO_OPEN_SHIFT'), color: 'error' })
            break
        }
        if (Array.isArray(body)) {
          const transactionErrors = ['DISABLED_ACCOUNT', 'LIMIT_EXCEEDED', 'NOT_ENOUGH_BALANCE', 'PAYMENT_GATEWAY_COMMUNICATION_ERROR', 'TRANSACTION_ALREADY_EXISTS']
          transactionErrors.forEach(errorCode => {
            if (body.some(e => e.error_code === errorCode)) {
              store.commit('state/setMessage', { text: i18n.t(`error.conflict.${errorCode}`), color: 'error' })
            }
          })
        }
      case 422:
        // this.formatUnknownEntity(JSON.parse(error.body.errors))
        break
      case 0:
        store.commit('state/setMessage', { text: i18n.t('error.server_disconnected'), color: 'error' })
        break
      case 500:
        store.commit('state/setMessage', { text: i18n.t('error.server_error'), color: 'error' })
        break
    }
  }
}
