import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { RefundRequest } from '@/entities/RefundRequest'
import camelCaseKeys from 'camelcase-keys'
const requestService = new RequestService()

export class RefundRequestRequests {
  list ({ limit, offset, order, status, createdSince, createdUntil, resolvedSince, resolvedUntil, filter }) {
    const route = ServerDirections.POS_REFUND_REQUESTS
    const method = 'get'
    const params = {
      limit, offset
    }
    if (order) {
      params.order = order
    }
    if (status) {
      params.status = status
    }
    if (createdSince) {
      params.created_since = createdSince
    }
    if (createdUntil) {
      params.created_until = createdUntil
    }
    if (resolvedSince) {
      params.resolved_since = resolvedSince
    }
    if (resolvedUntil) {
      params.resolved_until = resolvedUntil
    }
    if (filter) {
      params.filter = filter
    }
    function map (res) {
      return {
        list: res.data.map(item => {
          return new RefundRequest(item)
        }),
        meta: camelCaseKeys(res.meta)
      }
    }
    return requestService.makePromise(route, method, { params }, map)
  }

  accept (id) {
    const route = ServerDirections.POS_REFUND_REQUESTS_ID_ACCEPT.replace('{id}', id)
    const method = 'post'
    function map (id) {
      return id
    }
    return requestService.makePromise(route, method, {}, map)
  }

  reject (id, reason) {
    const route = ServerDirections.POS_REFUND_REQUESTS_ID_REJECT.replace('{id}', id)
    const method = 'post'
    const body = { reason }
    function map (id) {
      return id
    }
    return requestService.makePromise(route, method, { body }, map)
  }
}
