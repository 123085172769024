import { AccountRequests } from '@/services/account.requests'
const accountService = new AccountRequests()

const actions = {
  get ({ commit }, { id, save }) {
    return new Promise((resolve, reject) => {
      accountService.get(id).then(async (account) => {
        if (save) {
          commit('setRead', account)
        }
        resolve(account)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  actions
}
