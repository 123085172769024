import dayjs from 'dayjs'
import { RequestService } from '@/services/request.service'
import ServerDirections from '@/config/server-directions'
import { Facility } from '../entities/Facility'
import { Operator } from '../entities/Operator'
import { Role } from '../entities/Role'
const requestService = new RequestService()

export class AuthRequests {
  login (user, password, temp) {
    function map (data) {
      return {
        token: data.token,
        refreshToken: data.refresh_token,
        passwordExpiration: data.password_expires_at
          ? dayjs(data.password_expires_at)
          : null
      }
    }
    const route = ServerDirections.LOGIN
    const method = 'post'
    const body = {
      user,
      password
    }
    const disableStatusHandling = {}
    if (temp) {
      disableStatusHandling[401] = true
    }
    return requestService.makePromise(route, method, { body, noAuth: true, disableStatusHandling }, map)
  }

  refreshToken (refreshToken) {
    function map (data) {
      return data.token
    }
    const route = ServerDirections.REFRESH
    const method = 'post'
    const body = {
      refresh_token: refreshToken
    }
    return requestService.makePromise(route, method, { body, noAuth: true }, map)
  }

  whoAmI () {
    function map (data) {
      return {
        operator: new Operator(data.operator),
        role: new Role(data.role),
        facilities: data.facilities.map((f) => { return new Facility(f) })
      }
    }
    const route = ServerDirections.WHOAMI
    const method = 'get'
    return requestService.makePromise(route, method, {}, map)
  }

  revoke (operatorId) {
    function map (data) {
      return data
    }
    const route = ServerDirections.REVOKE
    const method = 'post'
    const body = {
      operator_id: operatorId
    }
    return requestService.makePromise(route, method, { body }, map)
  }
}
