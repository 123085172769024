import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Zone } from '@/entities/Zone'
const requestService = new RequestService()

export class ZoneRequests {
  list () {
    const route = ServerDirections.ZONES
    const method = 'get'
    function map (data) {
      return data.map(item => new Zone(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
