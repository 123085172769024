import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Peripheral } from '@/entities/Peripheral'
import { i18n } from '@/locale'
import store from '@/store'
const requestService = new RequestService()

export class PeripheralRequests {
  get (id) {
    const route = ServerDirections.PERIPHERALS_ID.replace('{id}', id)
    const method = 'get'
    function map (data) {
      return new Peripheral(data)
    }
    return requestService.makePromise(route, method, {}, map)
  }

  list () {
    const route = ServerDirections.PERIPHERALS
    const method = 'get'
    function map (data) {
      return data.map(item => new Peripheral(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }

  listAdopted () {
    const route = ServerDirections.ADOPTED_PERIPHERALS
    const method = 'get'
    function map (data) {
      return data.adopted_peripherals.map(item => { return { id: item.adoption.adoption_id } })
    }
    return requestService.makePromise(route, method, {}, map).catch((err) => {
      store.commit('state/setMessage', { text: i18n.t('peripherals.warnings.disconnected_peripheral_service'), color: 'error' }, { root: true })
      throw err
    })
  }
}
