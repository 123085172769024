import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { PricingGroup } from '@/entities/PricingGroup'
const requestService = new RequestService()

export class PricingGroupRequests {
  list () {
    const route = ServerDirections.PRICING_GROUPS
    const method = 'get'
    function map (data) {
      return data.map(item => new PricingGroup(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
