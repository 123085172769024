import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Station } from '@/entities/Station'
const requestService = new RequestService()
var snakeCaseKeys = require('snakecase-keys')

export class StationRequests {
  list () {
    const route = ServerDirections.STATIONS
    const method = 'get'
    function map (data) {
      return data.map(item => new Station(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
