import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Operator } from '@/entities/Operator'
const requestService = new RequestService()

export class OperatorRequests {
  list () {
    const route = ServerDirections.OPERATORS
    const method = 'get'
    function map (data) {
      return data.map(item => new Operator(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
