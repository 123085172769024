import Vue from 'vue'
import { i18n } from '@/locale'
import Router from 'vue-router'
import routeNames from './routes'
import store from '@/store'
import { BASE_URL } from '@/config/environment'

Vue.use(Router)
function firstCompatibleRouteName (resources) {
  for (let index = 0; index < routes.length; index++) {
    const route = routes[index]
    if (route.meta && !route.path.includes(':')) {
      const hasResource = resources.find((resource) => { return resource.alias === route.meta.resourceAlias })
      if (hasResource) {
        return route.name
      }
    }
  }
  return null
}
const routes = [
  {
    path: '/refund-requests',
    name: routeNames.refund_requests.name,
    component: () => import(/* webpackChunkName: 'refundRequests' */ '@/views/RefundRequests.vue'),
    meta: { requiresAuth: true, onlySupervisor: true }
  },
  {
    path: '/balance-adjustment/:id',
    name: routeNames.balance_adjustment.name,
    component: () => import(/* webpackChunkName: 'balanceAdjustment' */ '@/views/BalanceAdjustment.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/refund',
    name: routeNames.refund.name,
    component: () => import(/* webpackChunkName: 'refund' */ '@/views/Refund.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/package-order',
    name: routeNames.package_order.name,
    component: () => import(/* webpackChunkName: 'packageOrder' */ '@/views/PackageOrder.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/guests/:id',
    name: routeNames.guest.name,
    component: () => import(/* webpackChunkName: 'guest' */ '@/views/Guest.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tickets/:id',
    name: routeNames.ticket.name,
    component: () => import(/* webpackChunkName: 'ticket' */ '@/views/Ticket.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: routeNames.login.name,
    component: () => import(/* webpackChunkName: 'login' */ '@/views/Login.vue'),
    meta: { requiresAnon: true, requiresAuth: false }
  },
  {
    path: '/ticket-order',
    name: routeNames.ticket_order.name,
    component: () => import(/* webpackChunkName: 'ticketOrder' */ '@/views/TicketOrder.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ticket-validation',
    name: routeNames.ticket_validation.name,
    component: () => import(/* webpackChunkName: 'ticketValidation' */ '@/views/TicketValidation.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: routeNames.settings.name,
    component: () => import(/* webpackChunkName: 'settings' */ '@/views/Settings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/shifts',
    name: routeNames.shifts.name,
    component: () => import(/* webpackChunkName: 'login' */ '@/views/Shifts.vue'),
    meta: { requiresAuth: true, onlySupervisor: true }
  },
  {
    path: '*',
    redirect: { name: routeNames.settings.name }
  }
]

const router = new Router({
  mode: 'history',
  base: BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/authStatus']) {
      next({ name: routeNames.login.name })
    } else {
      store.dispatch('auth/whoAmI', { getSaved: true }).then((credentials) => {
        if (to.matched.some(record => record.meta.onlySupervisor) && !store.getters['shifts/isSupervisor']) {
          next({ name: routeNames.settings.name })
          return 
        }
        store.dispatch('shifts/reloadShifts')
        store.dispatch('peripherals/ping')
        if (to.matched.some(record => credentials.role.resources.findIndex((resource) => {
          return (resource.alias === 'ALL_RESOURCES') || (resource.alias === record.meta.resourceAlias)
        }) > -1 || !record.meta.resourceAlias)) {
          next()
        } else {
          const nextRoute = firstCompatibleRouteName(credentials.role.resources)
          if (nextRoute) {
            next({ name: nextRoute })
          } else {
            next(false)
          }
          store.commit('state/setMessage', { text: i18n.t('error.forbidden'), color: 'error' })
        }
      }).catch(() => {
        next(false)
      }).finally(() => {
        store.state.state.firstLoad = true
      })
    }
  } else if (to.matched.some(record => record.meta.requiresAnon) && store.getters['auth/authStatus']) {
    next({ name: routeNames.settings.name })
  } else if (to.matched.some(record => record.meta.requiresPasswordExpiration) && !store.getters['auth/passwordExpiration']) {
    next({ name: routeNames.login.name })
  } else {
    next()
  }
})

export default router
