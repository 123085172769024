import { OperatorRequests } from '@/services/operator.requests'
import Vue from 'vue'
const operatorService = new OperatorRequests()

const state = {
  list: []
}

const actions = {
  list ({ commit }, { save }) {
    return new Promise((resolve, reject) => {
      operatorService.list().then(async (operators) => {
        if (save) {
          commit('setList', operators)
        }
        resolve(operators)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setList (state, list) {
    state.list = list
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
