import { OrderRequests } from '@/services/order.requests'
import { i18n } from '@/locale'
import Vue from 'vue'

const orderService = new OrderRequests()

const state = {
  list: [],
  read: null
}

const getters = {
  someNickelodeonAppServerOrder: (state, getters, rootState) => {
    try {
      return state.list.some((order) => {
        return rootState.configuration.configuration.configuration.serviceTypesId.APPSERVER_NICKELODEON === order.serviceId
      })
    } catch {
      return null
    }
  }
}

const actions = {
  list ({ state }, { save, limit, offset, order, since, until, stationId, accountIds, orderIds }) {
    return new Promise((resolve, reject) => {
      orderService.list(limit, offset, order, since, until, stationId, accountIds, orderIds).then(async (response) => {
        if (save) {
          state.list = response.list
        }
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  decorateColumn ({ dispatch }, path) {
    switch (path) {
      case 'station.name':
        dispatch('decorateStation')
        break
      case 'service.name':
        dispatch('decorateService')
        break
      case 'paymentMethodType':
        dispatch('decoratePaymentMethodType')
        break
    }
  },
  decorateStation ({ state, commit, dispatch }) {
    dispatch('stations/list', {}, { root: true }).then((stations) => {
      const list = state.list.map((order) => {
        const station = stations.find((station) => station.id === order.stationId)
        return { ...order, station }
      })
      commit('setList', list)
    })
  },
  decorateService ({ state, commit, dispatch }) {
    dispatch('services/list', {}, { root: true }).then((services) => {
      const list = state.list.map((order) => {
        const service = services.find((service) => service.id === order.serviceId)
        return { ...order, service }
      })
      commit('setList', list)
    })
  },
  decoratePaymentMethodType ({ state, commit, dispatch, rootGetters, rootState }) {
    const promises = state.list.map((order) => {
      return orderService.listTransactions(order.id).then(async (response) => {
        await dispatch('paymentMethods/list', {}, { root: true })
        const orderTransaction = response.find((transaction) => {
          return transaction.type === 'PAYMENT' &&
            rootGetters['configuration/isCurrencyLegal'](transaction.currencyId)
        })
        if (!orderTransaction) {
          return null
        } else {
          var paymentMethod = rootState.paymentMethods.list.find((pM) => { return pM.id === orderTransaction.paymentMethodId })
          return paymentMethod ? paymentMethod.name : i18n.t('payment_methods.room_charge')
        }
      }).catch(error => {
        reject(error)
      })
    })
    Promise.all(promises).then((paymentMethodTypes) => {
      const list = state.list.map((order, index) => {
        return { ...order, paymentMethodType: paymentMethodTypes[index] }
      })
      commit('setList', list)
    })
  },
  decorateRefunded ({ commit }) {
    const promises = state.list.map((order) => {
      return orderService.listRefunds(order.id)
    })
    Promise.all(promises).then((refundData) => {
      const list = state.list.map((order, index) => {
        const refundList = refundData[index]
        return {
          ...order,
          refunded: !!refundList.length,
          orderRefundId: refundList.length ? refundList[0].id : null
        }
      })
      commit('setList', list)
    })
  },
  get ({ state }, { save, id }) {
    return new Promise((resolve, reject) => {
      orderService.get(id).then((order) => {
        if (save) {
          state.read = order
        }
        resolve(order)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getByReference ({ state }, { save, reference }) {
    return new Promise((resolve, reject) => {
      orderService.getByReference(reference).then((order) => {
        if (save) {
          state.list = [order]
        }
        resolve(order)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setList (state, list) {
    Vue.set(state, 'list', list)
  },
  resetList (state) {
    state.list = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
