import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Pass } from '@/entities/Pass'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
const requestService = new RequestService()

export class PassRequests {
  list () {
    const route = ServerDirections.POS_PASSES
    const method = 'get'
    function map (data) {
      return data.map(item => new Pass(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }

  purchase (body) {
    const route = ServerDirections.POS_PASSES
    const method = 'post'
    function map (data) {
      return camelcaseKeys(data)
    }
    return requestService.makePromise(route, method, { body: snakecaseKeys(body) }, map)
  }

  getTicket (passId, orderId) {
    const route = ServerDirections.POS_PASSES_ID_ORDER_ID_TICKET.replace('{passId}', passId).replace('{orderId}', orderId)
    const method = 'get'
    function map (data) {
      var bytes = new Uint8Array(data)
      var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '') // eslint-disable-line
      return btoa(binary)
    }
    return requestService.makePromise(route, method, { responseType: 'arraybuffer' }, map)
  }
}
