import Vue from 'vue'
import { PassRequests } from '@/services/pass.requests'
const passService = new PassRequests()

const state = {
  visitors: [{ name: null, lastName: null, pass: null }],
  pricingGroupId: null,
  currencyId: null,
  paymentMethodId: null,
  totalAmount: null
}

const actions = {
  complete ({ state, dispatch, rootGetters }) {
    return new Promise((resolve, reject) => {
      const exchangeValue = state.currencyId === rootGetters['configuration/foreignCurrency'].id ? rootGetters['configuration/foreignCurrencyExchangeRate'] : null
      const exchangeAmount = state.currencyId === rootGetters['configuration/foreignCurrency'].id ? ((state.totalAmount / exchangeValue) || 0) : null
      const exchangeCurrencyId = state.currencyId === rootGetters['configuration/foreignCurrency'].id ? state.currencyId : null
      passService.purchase({
        total: state.totalAmount || 0,
        pricingGroupId: state.pricingGroupId,
        currencyId: rootGetters['configuration/localCurrency'].id,
        paymentMethodId: state.paymentMethodId,
        stationId: localStorage.getItem('selectedStationId'),
        visitors: state.visitors.map((v) => {
          return {
            firstName: v.name,
            lastName: v.lastName,
            passId: v.pass
          }
        }),
        ...exchangeValue && { exchangeValue },
        ...exchangeAmount && { exchangeAmount },
        ...exchangeCurrencyId && { exchangeCurrencyId }
      }).then(({ bookingId, orderId }) => {
        resolve({ bookingId, orderId })
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  addVisitor (state) {
    if (!state.visitors.length) {
      state.visitors.push({ name: null, lastName: null, pass: null })
    } else {
      state.visitors.push({ name: state.visitors[0].name, lastName: state.visitors[0].lastName, pass: null })
    }
  },
  editVisitor (state, { index, visitor }) {
    var updatedVisitor = state.visitors[index]
    Object.keys(updatedVisitor).forEach(key => {
      if (visitor[key] !== undefined) {
        updatedVisitor[key] = visitor[key]
      }
    })
    Vue.set(state.visitors, index, updatedVisitor)
  },
  removeVisitor (state, index) {
    state.visitors.splice(index, 1)
  },
  clearOrder (state) {
    state.visitors = [{ name: null, lastName: null, pass: null }]
    state.pricingGroupId = null
    state.currencyId = null
    state.paymentMethodId = null
    state.totalAmount = null
  },
  setPricingGroupId (state, id) {
    state.pricingGroupId = id
  },
  setCurrencyId (state, id) {
    state.currencyId = id
  },
  setPaymentMethodId (state, id) {
    state.paymentMethodId = id
  },
  setTotalAmount (state, amount) {
    state.totalAmount = amount
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
