import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Package } from '@/entities/Package'
import snakecaseKeys from 'snakecase-keys'
const requestService = new RequestService()

export class PackageRequests {
  list () {
    const route = ServerDirections.POS_PACKAGES
    const method = 'get'
    function map (data) {
      return data.map(item => new Package(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }

  book (body) {
    const route = ServerDirections.POS_PACKAGES_BOOK
    const method = 'post'
    function map (data) {
      return data
    }
    return requestService.makePromise(route, method, { body: snakecaseKeys(body, { deep: true }) }, map)
  }

  purchase (body) {
    const route = ServerDirections.POS_PACKAGES
    const method = 'post'
    function map (data) {
      return data.id
    }
    return requestService.makePromise(route, method, { body: snakecaseKeys(body) }, map)
  }

  postAdjustments (body) {
    const route = ServerDirections.POS_PACKAGES_MANUAL
    const method = 'post'
    const useTemporalToken = !body.isSupervisor
    delete body.isSupervisor
    function map (data) {
      return data
    }
    return requestService.makePromise(route, method, { body: snakecaseKeys(body, { deep: true }), useTemporalToken }, map)
  }
}
