import { pricingGroupTypes, guestTypes, currencyTypes } from '@/config'
import { ConfigurationRequests } from '@/services/configuration.requests'
const configurationService = new ConfigurationRequests()

const state = {
  configuration: null
}

const getters = {
  configuration: state => state.configuration,
  currencies: state => state.configuration ? state.configuration.configuration.currencies : [],
  localCurrency: state => state.configuration ? state.configuration.configuration.localCurrency : null,
  foreignCurrency: state => state.configuration ? state.configuration.configuration.foreignCurrency : null,
  paymentMethodIds: state => state.configuration ? state.configuration.posConfiguration.paymentMethodIds : [],
  manualPaymentMethodId: state => state.configuration ? state.configuration.posConfiguration.manualPaymentMethodId : null,
  pricingGroups: state => state.configuration ? state.configuration.configuration.pricingGroups : [],
  paymentMethods: (state, getters, rootState) => getters.paymentMethodIds && rootState.paymentMethods.list.length
    ? getters.paymentMethodIds.map((id) => {
      return rootState.paymentMethods.list.find((pM) => {
        return pM.id === id
      })
    }) : [],
  parkFacilityId: state => state.configuration ? state.configuration.configuration.parkFacilityId : null,
  hotelFacilityId: state => state.configuration ? state.configuration.configuration.hotelFacilityId : null,
  exchangeCurrencies: state => state.configuration ? state.configuration.posConfiguration.exchangeCurrencies : [],
  foreignCurrencyExchangeRate: state => state.configuration ? state.configuration.posConfiguration.foreignCurrencyExchangeRate : null,
  isGuestChild: state => (guest) => { try { return state.configuration.configuration.guestTypesId.CHILD === guest.typeId } catch { return false } },
  cashPaymentMethodId: state => state.configuration ? state.configuration.posConfiguration.cashPaymentMethodId : null,
  isGuestChild: state => (guest) => {
    try {
      const { CHILD, INFANT } = state.configuration.configuration.guestTypesId
      return [CHILD, INFANT].includes(guest.typeId)
    } catch {
      return false
    }
  },
  getCurrency: (state, getters) => currencyId => { try { return getters.currencies.find(c => c.id === currencyId) } catch { return null }},
  getCurrencyName: (state, getters) => currencyId => { try { return getters.currencies.find(c => c.id === currencyId).name } catch { return '' }},
  isCurrencyLegal: (state, getters) => currencyId => { try { return getters.currencies.find(c => c.id === currencyId).type === currencyTypes.LEGAL } catch { return false }},
  getGuestType: state => (guestTypeId) => {
    try {
      return Object.values(guestTypes).find((guestType) => {
        return guestTypeId === state.configuration.configuration.guestTypesId[guestType]
      })
    } catch {
      return null
    }
  },
  pricingGroupType: (state) => (pricingGroupId) => {
    try {
      return Object.values(pricingGroupTypes).find((pricingGroupType) => {
        return pricingGroupId === state.configuration.configuration.pricingGroupTypesId[pricingGroupType]
      })
    } catch {
      return null
    }
  },
  isParkBooking: (state, getters) => (booking) => {
    try {
      return getters.parkFacilityId === booking.facilityId
    } catch {
      return false
    }
  },
  appServerServiceId: (state) => {
    try {
      return state.configuration.configuration.serviceTypesId.APPSERVER_NICKELODEON
    } catch {
      return null
    }
  },
  onlineTicketingServiceId: (state) => {
    try {
      return state.configuration.configuration.serviceTypesId.ONLINE_TICKETING
    } catch {
      return null
    }
  }
}

const actions = {
  get: ({ state, dispatch }) => {
    return new Promise((resolve, reject) => {
      configurationService.get().then(async (configuration) => {
        try {
          configuration.posConfiguration.foreignCurrencyExchangeRate = configuration.posConfiguration.exchangeCurrencies.find((c) => {
            return c.currency.id === configuration.configuration.currencyTypesId.FOREIGN
          }).exchangeRate
        } catch (error) {
          configuration.posConfiguration.foreignCurrencyExchangeRate = null
        }
        var promises = []
        promises.push(dispatch('currencies/list', {}, { root: true }).then((currencies) => {
          configuration.configuration.currencies = configuration.configuration.currencyIds.map((id) => {
            return currencies.find((c) => c.id === id)
          })
          configuration.configuration.localCurrency = currencies.find((c) => {
            return c.id === configuration.configuration.currencyTypesId.LOCAL
          })
          configuration.configuration.foreignCurrency = currencies.find((c) => {
            return c.id === configuration.configuration.currencyTypesId.FOREIGN
          })
        }))
        promises.push(dispatch('pricingGroups/list', {}, { root: true }).then((pricingGroups) => {
          configuration.configuration.pricingGroups = Object.values(configuration.configuration.pricingGroupTypesId).map((id) => {
            return pricingGroups.find((pG) => pG.id === id)
          })
        }))
        promises.push(dispatch('facilities/get', { id: configuration.configuration.parkFacilityId }, { root: true }))
        Promise.all(promises).then(() => {
          state.configuration = configuration
          resolve(configuration)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  initialization: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      var promises = []
      var credentials, shift, configuration
      promises.push(dispatch('auth/whoAmI', { getSaved: true }, { root: true }).then((cred) => { credentials = cred }))
      promises.push(dispatch('shifts/getOperatorShift', {}, { root: true }).then((s) => {shift = s })) // FIXME: comprobar si peta al no ser de rol permitido
      if (localStorage.getItem('selectedPrinter')) {
        promises.push(dispatch('shifts/getPrinterShift', { id: localStorage.getItem('selectedPrinter') }, { root: true }).then((s) => {shift = s }))
      }
      if (state.configuration) {
        configuration = state.configuration
      } else {
        promises.push(dispatch('get').then((conf) => { configuration = conf }))
      }
      Promise.all(promises).then(() => {
        resolve({
          credentials,
          configuration,
          shift
        })
      }).catch((error) => {
        reject(error)
      })
    })
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions
}
