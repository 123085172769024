import Vue from 'vue'
import { PackageRequests } from '@/services/package.requests'
const packageService = new PackageRequests()

const state = {
  guestId: null,
  pricingGroupId: null,
  currencyId: null,
  paymentMethodId: null,
  totalAmount: null,
  cartPackages: [],
  accountId: null,
  bookedPackages: [],
  bookingErrorPackages: []
}

const getters = {
  getPackageAmount: (state) => (packageId) => {
    const packageInCart = state.cartPackages.find((cartPackage) => cartPackage.packageId === packageId)
    return packageInCart ? packageInCart.quantity : 0
  },
  getBookedPackages: (state) => ({ packageId, accountId }) => {
    const index = state.bookedPackages.findIndex(bookedPackage => bookedPackage.packageId === packageId && bookedPackage.accountId === accountId)
    if (index >= 0) {
      return state.bookedPackages[index].quantity
    } return 0
  },
  isAccountLimitExceeded: (state, getters) => ({ pkg, curr }) => {
    const { tokenAmount, maxTokenAmountOnAccount, id } = pkg
    const amount = getters.getPackageAmount(id)
    const totalTokens = (curr + amount) * tokenAmount
    return !!(amount && maxTokenAmountOnAccount && totalTokens > maxTokenAmountOnAccount)
  }
}

const actions = {
  complete ({ state, rootGetters }) {
    return new Promise((resolve, reject) => {
      const exchangeValue = state.currencyId === rootGetters['configuration/foreignCurrency'].id ? rootGetters['configuration/foreignCurrencyExchangeRate'] : null
      const exchangeAmount = state.currencyId === rootGetters['configuration/foreignCurrency'].id ? state.totalAmount / exchangeValue : null
      const exchangeCurrencyId = state.currencyId === rootGetters['configuration/foreignCurrency'].id ? state.currencyId : null
      packageService.purchase({
        packages: state.cartPackages,
        totalAmount: state.totalAmount,
        pricingGroupId: state.pricingGroupId,
        guestId: state.guestId,
        currencyId: rootGetters['configuration/localCurrency'].id,
        paymentMethodId: state.paymentMethodId,
        stationId: localStorage.getItem('selectedStationId'),
        ...exchangeValue && { exchangeValue },
        ...exchangeAmount && { exchangeAmount },
        ...exchangeCurrencyId && { exchangeCurrencyId }
      }).then((id) => {
        resolve(id)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  book ({ state, commit }, { productId, quantity }) {
    return new Promise((resolve, reject) => {
      packageService.book({ quantity, productId, accountId: state.accountId }).then((data) => {
        commit('setBookedPackage', { packageId: productId, accountId: state.accountId, quantity })
        commit('packages/updateAvailable', { id: productId, available: data.available }, { root: true })
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  clearOrder ({ state, dispatch, rootGetters }, fullClear) {
    state.cartPackages = []
    state.currencyId = null
    state.totalAmount = null
    state.pricingGroupId = null
    state.paymentMethodId = null
    state.errors = {}
    if (fullClear) {
      state.guestId = null
      const bookedPackages = state.bookedPackages.filter(bookedPackage => bookedPackage.accountId !== state.accountId)
      const removedPackages = state.bookedPackages.filter(p => !bookedPackages.includes(p))
      const promises = removedPackages.filter(removedPackage => {
        const pkg = rootGetters['packages/getPackage']({ id: removedPackage.packageId })
        if (pkg.mustBeBooked) {
          return dispatch('book', { productId: pkg.id, quantity: 0 })
        }
      })
      Promise.all(promises).then(() => {
        Vue.set(state, 'bookedPackages', bookedPackages)
        state.accountId = null
      })
    }
  }
}

const mutations = {
  setGuestId (state, id) {
    state.guestId = id
  },
  setPricingGroupId (state, id) {
    state.pricingGroupId = id
  },
  setAccountId (state, id) {
    state.accountId = id
  },
  setCurrencyId (state, id) {
    state.currencyId = id
  },
  setPaymentMethodId (state, id) {
    state.paymentMethodId = id
  },
  setTotalAmount (state, amount) {
    state.totalAmount = amount
  },
  setPackageQuantity (state, { packageId, quantity }) {
    const index = state.cartPackages.findIndex(cartPackage => cartPackage.packageId === packageId)
    if (index === -1) {
      if (quantity !== 0 && !Number.isNaN(quantity)) {
        Vue.set(state.cartPackages, state.cartPackages.length, { packageId, quantity })
      }
    } else {
      if (quantity === 0 || Number.isNaN(quantity)) {
        Vue.set(state, 'cartPackages', state.cartPackages.filter((cP, i) => i !== index))
      } else {
        Vue.set(state.cartPackages, index, { packageId, quantity })
      }
    }
  },
  setBookingErrorPackages (state, id) {
    Vue.set(state.bookingErrorPackages, state.bookingErrorPackages.length, id)
  },
  deleteBookingErrorPackages (state, id) {
    Vue.delete(state.bookingErrorPackages, state.bookingErrorPackages.indexOf(id))
  },
  resetBookingErrorPackages (state) {
    Vue.set(state, 'bookingErrorPackages', [])
  },
  setBookedPackage (state, { packageId, quantity, accountId }) {
    const index = state.bookedPackages.findIndex(bookedPackage => bookedPackage.packageId === packageId && bookedPackage.accountId === accountId)
    if (index === -1) {
      if (quantity) {
        Vue.set(state.bookedPackages, state.bookedPackages.length, { packageId, quantity, accountId })
      }
    } else {
      if (!quantity) {
        Vue.set(state, 'bookedPackages', state.bookedPackages.filter((bP, i) => i !== index))
      } else {
        Vue.set(state.bookedPackages, index, { packageId, quantity, accountId })
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
