import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Service } from '@/entities/Service'
const requestService = new RequestService()

export class ServiceRequests {
  list () {
    const route = ServerDirections.SERVICES
    const method = 'get'
    function map (data) {
      return data.map(item => new Service(item))
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
