import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Profile } from '@/entities/Profile'
var snakeCaseKeys = require('snakecase-keys')
const requestService = new RequestService()

export class ProfileRequests {
  get (id) {
    const route = ServerDirections.PROFILES_ID.replace('{id}', id)
    const method = 'get'
    function map (data) {
      return new Profile(data)
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
