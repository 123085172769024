import { PackageRequests } from '@/services/package.requests'
import Vue from 'vue'
const packageService = new PackageRequests()

const state = {
  list: []
}

const getters = {
  getPackage: (state) => ({ id, currencyId }) => {
    return state.list.find((pkg) => {
      return id
        ? pkg.id === id
        : currencyId
          ? pkg.tokenCurrencyId === currencyId
          : null
    })
  },
  getPackageField: (state, getters) => ({ id, field, currencyId }) => {
    const pkg = getters.getPackage({ id, currencyId })
    return pkg ? pkg[field] : null
  }
}

const actions = {
  list ({ state }) {
    return new Promise((resolve, reject) => {
      packageService.list().then((packages) => {
        state.list = packages
        resolve(packages)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setList (state, list) {
    state.list = list
  },
  updateAvailable (state, { id, available }) {
    const pkg = state.list.find(p => p.id === id)
    Vue.set(pkg, 'currentAvailableProducts', available)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
