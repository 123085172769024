import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['state'])
  },
  data () {
    return {
      communicator: null,
      open: false
    }
  },
  created () {
  },
  methods: {
    disconnect () {
      this.state.peripheralConnector.close()
    },
    connect () {
      return new Promise(async (resolve, reject) => {
        const peripherals = []
        if (localStorage.getItem('selectedReader')) {
          peripherals.push(localStorage.getItem('selectedReader'))
        }
        if (localStorage.getItem('selectedPrinter')) {
          peripherals.push(localStorage.getItem('selectedPrinter'))
        }
        try {
          if (!peripherals.length) {
            this.$store.commit('state/setMessage', { text: this.$t('error.no_peripheral'), color: 'warning' })
            resolve()
          } else {
            try {
              await this.state.peripheralConnector.connect(peripherals)
              resolve()
            } catch (error) {
              reject(error)
            }
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    cancelAction () {
      this.state.peripheralConnector.cancelLast()
    },
    retry () {
      this.$store.commit('state/setReaderError', null)
      this.state.peripheralConnector.retry()
    },
    async sendAction (type, payload, resolve, reject) {
      if (!this.state.peripheralConnector || !this.state.peripheralConnector.isConnected()) {
        await this.$store.dispatch('state/connectPeripheralSocket').catch((error) => {
          reject(error)
        })
        await this.connect().catch((error) => {
          this.$store.commit('state/setMessage', { text: this.$t('reader.error.socket_connection'), color: 'error' })
          reject(error)
        })
      }
      if (this.state.peripheralConnector.isConnected()) {
        try {
          this.state.peripheralConnector.send(
            localStorage.getItem('selectedReader'),
            'REQUEST',
            { payload, type },
            () => {
              this.$store.commit('state/setReaderConnected', true)
            },
            (payload) => {
              if (resolve) {
                resolve(payload)
              }
            },
            (payload) => {
              if (reject) {
                reject(payload)
              }
            }
          )
        } catch (e) {
          reject(e)
        }
      } else {
        reject(new Error('Could not connect to peripheral'))
      }
    }
  }
}
