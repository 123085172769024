import { PassRequests } from '@/services/pass.requests'
const passService = new PassRequests()

const state = {
  list: []
}

const actions = {
  list ({ state }) {
    return new Promise((resolve, reject) => {
      passService.list().then((passes) => {
        state.list = passes
        resolve(passes)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions
}
