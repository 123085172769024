import { PeripheralCommunication } from '@/services/socket-peripheral.service'
import { RequestService } from '@/services/request.service'
import { API_ROOT, SOCKET_SERVER } from '@/config/environment'
const requestService = new RequestService()

const state = {
  loading: null,
  firstLoad: false,
  message: {
    text: null,
    color: null,
    show: null,
    timeout: parseInt(process.env.VUE_APP_MESSAGE_TIMEOUT, 10)
  },
  formErrors: null,
  reader: {
    open: false,
    error: null,
    connected: false,
    showConnecting: false
  },
  assignReader: {
    open: false,
    guests: null
  },
  printer: {
    printing: false,
    file: null
  },
  peripheralConnector: null,
  error: null
}

const actions = {
  connectPeripheralSocket ({ state }) {
    return new Promise((resolve, reject) => {
      if (!state.peripheralConnector) {
        try {
          state.peripheralConnector = new PeripheralCommunication(requestService.authHeader, API_ROOT, SOCKET_SERVER, () => {})
          resolve(state.peripheralConnector)
        } catch (error) {
          reject(error)
        }
      }
      resolve(state.peripheralConnector)
    })
  },
  handleFormError ({ commit }, {
    error, // body: { errors: [] }
    errors, // Array
    value, // Error identifier value
    path, // Error identifier path. Default is "name"
    savePath, // Property name to save with instead of name prop 
    text, // Value to save
    condition, // Function,
    noRemoval // Boolean. Used when multiple errors for same value
  }) {
    const array = errors || (error && error.body ? error.body.errors : [])
    const _path = path || 'name'
    let foundError = null
    if (Array.isArray(array)) {
      foundError = array.find(e => (e[_path] === value) && (condition ? condition(e) : true))
    }
    if (foundError) {
      commit('addFormError', { [savePath || value]: [text] })
    } else if (!noRemoval) {
      commit('deleteFormError', savePath || value)
    }
    return foundError
  }
}

const mutations = {
  setLoading (state, value) {
    if (state.formErrors && value) {
      state.formErrors = null
    }
    state.loading = value
  },
  setMessage (state, message) {
    state.message.text = message.text
    state.message.color = message.color
    state.message.timeout = message.timeout
    state.message.show = true
  },
  hideMessage () {
    state.message.show = false
  },
  resetFormErrors (state) {
    state.formErrors = null
  },
  setFormErrors (state, errors) {
    state.formErrors = errors
  },
  addFormError (state, error) {
    if (!state.formErrors) {
      state.formErrors = error
    } else {
      state.formErrors = {
        ...state.formErrors,
        ...error
      }
    }
  },
  deleteFormError (state, fieldName) {
    if (state.formErrors) {
      delete state.formErrors[fieldName]
    }
  },
  setReaderState (state, value) {
    state.reader.open = value
    if (value) {
      setTimeout(() => {
        state.reader.showConnecting = true
      }, 200)
    } else {
      state.reader.error = null
      state.reader.connected = false
      state.reader.showConnecting = false
    }
  },
  setAssignReaderState (state, guests) {
    state.assignReader.open = !!guests
    if (guests) {
      state.assignReader.guests = guests
      setTimeout(() => {
        state.reader.showConnecting = true
      }, 200)
    } else {
      state.assignReader.guests = null
      state.reader.error = null
      state.reader.connected = false
      state.reader.showConnecting = false
    }
  },
  setPrinterState (state, { file }) {
    state.printer.printing = !!file
    if (file) {
      setTimeout(() => {
        state.reader.showConnecting = true
      }, 200)
    } else {
      state.printer.printing = false
      state.printer.file = null
    }
  },
  setReaderError (state, value) {
    state.reader.error = value
  },
  setReaderConnected (state, value) {
    state.reader.connected = value
  },
  setError (state, error) {
    state.error = error
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
