import { ZoneRequests } from '@/services/zone.requests'
const zoneService = new ZoneRequests()

const state = {
  list: []
}

const actions = {
  list ({ commit }, { save }) {
    return new Promise((resolve, reject) => {
      zoneService.list().then(async (zones) => {
        if (save) {
          commit('setList', zones)
        }
        resolve(zones)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setList (state, list) {
    Vue.set(state, 'list', list)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
