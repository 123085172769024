import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import configuration from './modules/configuration'
import currencies from './modules/currencies'
import facilities from './modules/facilities'
import operators from './modules/operators'
import pagination from './modules/pagination'
import paymentMethods from './modules/payment-methods'
import peripherals from './modules/peripherals'
import pricingGroups from './modules/pricing-groups'
import passes from './modules/passes'
import settings from './modules/settings'
import shifts from './modules/shifts'
import state from './modules/state'
import stations from './modules/stations'
import bookings from './modules/bookings'
import profiles from './modules/profiles'
import zones from './modules/zones'
import ticketOrder from './modules/ticket-order'
import accounts from './modules/accounts'
import orders from './modules/orders'
import services from './modules/services'
import transactions from './modules/transactions'
import guests from './modules/guests'
import packageOrder from './modules/package-order'
import packages from './modules/packages'
import refundRequests from './modules/refund-requests'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    configuration,
    currencies,
    facilities,
    operators,
    pagination,
    paymentMethods,
    peripherals,
    pricingGroups,
    passes,
    settings,
    shifts,
    state,
    stations,
    bookings,
    profiles,
    zones,
    ticketOrder,
    accounts,
    packageOrder,
    packages,
    guests,
    packages,
    packageOrder,
    guests,
    orders,
    services,
    transactions,
    refundRequests
  }
})
