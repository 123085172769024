import dayjs from 'dayjs'
import routeNames from '../../router/routes'
import router from '@/router'
import { AuthRequests } from '@/services/auth.requests'
import jwt from 'jsonwebtoken'
import ServerDirections from '@/config/server-directions'
const authService = new AuthRequests()
const state = {
  credentials: null,
  temporalSupervisorToken: null,
  resources: null,
  authToken: localStorage.getItem('token') !== 'null' ? localStorage.getItem('token') : null,
  refreshToken: localStorage.getItem('refreshToken') !== 'null' ? localStorage.getItem('refreshToken') : null,
  passwordExpiration: localStorage.getItem('passwordExpiration') !== 'null' ? dayjs(localStorage.getItem('passwordExpiration')) : null
}

const getters = {
  authStatus: state => state.authToken != null &&
    state.refreshToken != null &&
    (!state.passwordExpiration || state.passwordExpiration.isAfter(dayjs())),
  authToken: state => state.authToken,
  refreshToken: state => state.refreshToken,
  passwordExpiration: state => state.passwordExpiration,
  operatorId: state => state.authToken ? jwt.decode(state.authToken).sub : null
}

const actions = {
  signOut: ({ commit }) => {
    commit('removeTokens')
    router.push({ name: routeNames.login.name })
  },
  signIn: ({ commit, rootGetters }, { user, password, temp }) => {
    return new Promise((resolve, reject) => {
      authService.login(user, password, temp).then(({ token, refreshToken, passwordExpiration }) => {
        if (temp) {
          commit('saveTemporalSupervisorToken', { token })
        } else {
          commit('saveTokens', { token, refreshToken, passwordExpiration })
          if (rootGetters['shifts/isSupervisor'] && (!rootGetters['settings/selectedStationId'])) {
            router.push({ name: routeNames.settings.name })
          } else {
            router.push({ name: routeNames.ticket_order.name })
          }
        }
        resolve()
      }).catch((err) => {
        reject(err)
      })
    })
  },
  refreshToken: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      authService.refreshToken(state.refreshToken).then((token) => {
        commit('replaceAuthToken', token)
        resolve(token)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  revokeAuth ({ state }, { operator }) {
    return new Promise((resolve, reject) => {
      authService.revoke(operator.id).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  whoAmI ({ commit, state, dispatch }, { getSaved }) {
    return new Promise((resolve, reject) => {
      if (getSaved && state.credentials) {
        resolve(state.credentials)
      } else {
        authService.whoAmI().then(async (credentials) => {
          commit('setCredentials', credentials)
          await dispatch('currencies/list', {}, { root: true }).then((currencies) => {
            credentials.facilities = credentials.facilities.map((f) => {
              f.baseCurrency = currencies.find((c) => c.id === f.baseCurrencyId)
              return f
            })
          })
          dispatch('state/connectPeripheralSocket', {}, { root: true })
          resolve(credentials)
        }).catch((error) => {
          if (error.body && error.body.message === 'Operator does not exist.') {
            dispatch('signOut')
          }
          reject(error)
        })
      }
    })
  },
  emailPassword ({}, { id }) {
    var xmlHttp = new XMLHttpRequest()
    xmlHttp.open("post", ServerDirections.PASSWORD_RECOVERY, false)
    xmlHttp.send(JSON.stringify({ id }))
    return xmlHttp.responseText
  }
}

const mutations = {
  saveTemporalSupervisorToken: (state, { token }) => {
    state.temporalSupervisorToken = token
  },
  removeTemporalSupervisorToken: (state) => {
    state.temporalSupervisorToken = null
  },
  saveTokens: (state, { token, refreshToken, passwordExpiration }) => {
    state.authToken = token
    state.refreshToken = refreshToken
    state.passwordExpiration = passwordExpiration
    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('passwordExpiration', passwordExpiration ? passwordExpiration.toISOString() : null)
  },
  replaceAuthToken: (state, token) => {
    state.authToken = token
    localStorage.setItem('token', token)
  },
  replaceRefreshToken: (state, token) => {
    state.refreshToken = token
    localStorage.setItem('refreshToken', token)
  },
  removeTokens: (state) => {
    state.authToken = null
    state.refreshToken = null
    state.credentials = null
    state.passwordExpiration = null
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('resources')
    localStorage.removeItem('passwordExpiration')
  },
  setCredentials: (state, credentials) => {
    state.resources = credentials.role.resources
    localStorage.setItem('resources', JSON.stringify(state.resources))
    state.credentials = credentials
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
