import { RequestService } from '@/services/request.service'
import ServerDirections from '@/config/server-directions'
import { Configuration } from '@/entities/Configuration'
const requestService = new RequestService()

export class ConfigurationRequests {
  get () {
    function map (data) {
      return new Configuration(data)
    }
    const route = ServerDirections.POS_CONFIG
    const method = 'get'
    return requestService.makePromise(route, method, {}, map)
  }
}
