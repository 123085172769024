import { RefundRequestRequests } from '@/services/refund-request.requests'
import Vue from 'vue'
const refundRequestService = new RefundRequestRequests()

const state = {
  list: []
}

const actions = {
  list ({ commit }, { save, limit, offset, order, status, createdSince, createdUntil, resolvedSince, resolvedUntil, filter }) {
    return new Promise((resolve, reject) => {
      refundRequestService.list({ limit, offset, order, status, createdSince, createdUntil, resolvedSince, resolvedUntil, filter }).then(async (res) => {
        if (save) {
          commit('setList', res.list)
        }
        resolve(res)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  decorateColumn ({ dispatch }, path) {
    switch (path) {
      case 'guest':
        dispatch('decorateGuest')
        break
    }
  },
  decorateGuest ({ state, dispatch }) {
    const promises = state.list.map((refundRequest) => dispatch('guests/get', { id: refundRequest.guestId, decorate: true }, { root: true }))
    Promise.all(promises).then((guests) => {
      state.list = state.list.map((refundRequest, i) => {
        return { ...refundRequest, guest: guests[i] }
      })
    })
  }
}

const mutations = {
  setList (state, list) {
    Vue.set(state, 'list', list)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
