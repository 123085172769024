import { StationRequests } from '@/services/station.requests'
const stationService = new StationRequests()
import Vue from 'vue'

const state = {
  list: []
}

const actions = {
  list ({ commit, dispatch }, { decorate, save }) {
    return new Promise((resolve, reject) => {
      stationService.list().then(async (stations) => {
        if (save) {
          commit('setList', stations)
        }
        if (decorate) {
          const promises = []
          promises.push(
            dispatch('zones/list', {}, { root: true }).then((zones) => {
              stations.forEach((station) => {
                station.zone = zones.find((zone) => zone.id === station.zoneId)
              })
            })
          )
          await Promise.all(promises)
        }
        resolve(stations)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setList (state, list) {
    Vue.set(state, 'list', list)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
