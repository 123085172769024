import { PricingGroupRequests } from '@/services/pricing-group.requests'
const pricingGroupService = new PricingGroupRequests()

const state = {
  list: []
}

const actions = {
  list ({ state }) {
    return new Promise((resolve, reject) => {
      pricingGroupService.list().then((pricingGroups) => {
        state.list = pricingGroups
        resolve(pricingGroups)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions
}
