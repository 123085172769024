import io from 'socket.io-client'
import store from '@/store'
import { peripheralTimeoutError } from '@/config'
const uuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}
export class PeripheralCommunication {
  constructor (getAuthToken, appServer, socketServer, onMessage) {
    const pendingRequests = {}
    var lastRequestId = null
    var lastPeripherals = null

    const getToken = async (apiKey, peripherals) => {
      const response = await fetch(`${appServer}/peripherals/token`, {
        headers: {
          'x-api-key': apiKey
        },
        method: 'POST',
        body: JSON.stringify({ ids: peripherals })
      })
      if (response.ok) {
        return (await response.json()).token
      } else {
        throw new Error('Could not fetch socket token.')
      }
    }

    let socket
    const close = () => {
      if (socket && socket.connected) {
        socket.close()
      }
    }
    const connect = (peripherals) => {
      return new Promise(async (resolve, reject) => {
        lastPeripherals = peripherals
        if (socket && socket.connected) {
          resolve()
        }
        try {
          socket = await io(socketServer, {
            reconnection: 'true',
            reconnectionAttempts: 2,
            query: {
              auth_token: await getToken(await getAuthToken(), peripherals)
            }
          })
        } catch (error) {
          reject(error)
        }
        if (!socket) {
          reject(new Error('Could not connect to selected peripheral'))
        } else {
          socket.on('message', data => {
            if (onMessage) {
              onMessage(data)
            }
            if (pendingRequests[data.id]) {
              if (data.action === 'ACK') {
                lastRequestId = data.id
                if (pendingRequests[data.id].onAck) {
                  pendingRequests[data.id].onAck()
                }
              } else if (data.action === 'RESPONSE') {
                if (pendingRequests[data.id].onResponse) {
                  pendingRequests[data.id].onResponse(data.payload ? JSON.parse(data.payload) : undefined)
                }
              } else if (data.action === 'ERROR') {
                if (pendingRequests[data.id].onError) {
                  pendingRequests[data.id].onError(data.payload ? JSON.parse(data.payload) : undefined)
                }
              }
            }
          })
          socket.on('connect', () => {
            console.log('Connected to server.')
            resolve()
          })

          socket.on('disconnect', reason => {
            console.log('Disconnected from server.', reason)
          })

          socket.on('reconnect_attempt', async attemptNumber => {
            console.log('Reconnection attempt.')
            socket.io.opts.query = {
              auth_token: await getToken(await getAuthToken(), lastPeripherals)
            }
          })

          socket.on('reconnect', attemptNumber => {
            console.log('Reconnected.')
          })

          socket.on('reconnect_error', error => {
            console.log('Reconnect error.')
            console.log(error)
          })

          socket.on('reconnect_failed', () => {
            console.log('Reconnect failed.')
          })

          socket.on('connect_error', error => {
            console.log('Connect error')
            console.log(error)
            reject(error)
          })

          socket.on('connect_timeout', timeout => {
            console.log('Connect timeout')
            console.log(timeout)
          })

          socket.on('error', error => {
            console.log('Error')
            console.log(error)
            store.commit('state/setReaderError', error)
          })
        }
      })
    }

    const isConnected = () => {
      return !!socket && socket.connected
    }

    const send = async (peripheral, action, { payload, data, contentType, type }, onAck, onResponse, onError) => {
      try {
        var request = null
        let finished = false
        setTimeout(() => {
          if (!finished) {
            onError({...peripheralTimeoutError, peripheral, type })
          }
        }, 30000)
        if (action === 'REQUEST') {
          request = {
            id: uuid(),
            token: store.getters['auth/authToken'],
            action,
            type,
            peripheral,
            payload: payload ? JSON.stringify(payload) : undefined
          }
        } else if (action === 'PRINT') {
          request = {
            id: uuid(),
            token: store.getters['auth/authToken'],
            action: 'REQUEST',
            type: action,
            peripheral,
            payload: JSON.stringify({ data, content_type: contentType })
          }
        }
        console.log(request)
        socket.send(request)
        pendingRequests[request.id] = {
          peripheral,
          request,
          onAck,
          onResponse: function (payload) {
            finished = true
            onResponse(payload)
          },
          onError: function (payload) {
            finished = true
            onError(payload)
          }
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    }
    const retry = () => {
      var pending = pendingRequests[lastRequestId]
      pending.request.id = uuid()
      socket.send(pending.request)
      pendingRequests[pending.request.id] = { peripheral: pending.peripheral, request: pending.request, onAck: pending.onAck, onResponse: pending.onResponse, onError: pending.onError }
    }
    const cancelLast = () => {
      cancel(lastRequestId)
    }

    const cancel = (id) => {
      if (pendingRequests[id]) {
        const request = pendingRequests[id].request
        request.action = 'CANCEL'
        socket.send(request)
        pendingRequests[id] = undefined
      }
    }

    return { send, cancel, connect, isConnected, close, cancelLast, retry }
  }
}
