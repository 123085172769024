import { ProfileRequests } from '@/services/profile.requests'
const profileService = new ProfileRequests()

const state = {
  read: null,
  edited: null
}

const actions = {
  get ({ commit }, { id, save }) {
    return new Promise((resolve, reject) => {
      profileService.get(id).then(async (profile) => {
        if (save) {
          commit('setRead', profile)
        }
        resolve(profile)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setRead (state, profile) {
    state.read = profile
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
