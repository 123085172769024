import { PeripheralRequests } from '@/services/peripheral.requests'
import { i18n } from '@/locale'
import Vue from 'vue'

const peripheralService = new PeripheralRequests()

const state = {
  list: [],
  completeList: [],
  read: null,
  disconnectedPeripheralService: false,
  selectedPrinter: localStorage.getItem('selectedPrinter'),
  selectedReader: localStorage.getItem('selectedReader')
}

const actions = {
  get ({ commit }, { id, save }) {
    return new Promise((resolve, reject) => {
      if (id) {
        peripheralService.get(id).then(async (peripheral) => {
          if (save) {
            commit('setRead', peripheral)
          }
          resolve(peripheral)
        }).catch((error) => {
          reject(error)
        })
      } else {
        resolve(null)
      }
    })
  },
  listAll ({ state, dispatch }, { save, decorate }) {
    return new Promise((resolve, reject) => {
      peripheralService.list()
        .then(async (list) => {
          if (decorate) {
            await dispatch('stations/list', { decorate: true, save: true }, { root: true }).then((stations) => {
              list.forEach((peripheral) => {
                peripheral.station = stations.find((station) => station.id === peripheral.stationId)
              })
            })
          }
          if (save) {
            state.completeList = list
          }
          resolve(list)
        }).catch(error => {
          reject(error)
        })
    })
  },
  list ({ state, commit, dispatch }, { save, decorate }) {
    state.disconnectedPeripheralService = false
    return new Promise((resolve, reject) => {
      var promises = []
      var peripherals = null
      var adopted = []
      promises.push(peripheralService.list().then((p) => peripherals = p))
      promises.push(peripheralService.listAdopted().then((a) => adopted = Array.isArray(a) ? a : []))
      Promise.all(promises).then(async () => {
        var list = adopted.map((a) => {
          var peripheral = peripherals.find((p) => { return p.id === a.id })
          return peripheral || null
        })
        if (save) {
          commit('setList', list)
        }
        if (decorate) {
          const promises = []
          promises.push(
            dispatch('stations/list', { decorate: true, save: true }, { root: true }).then((stations) => {
              peripherals.forEach((peripheral) => {
                peripheral.station = stations.find((station) => station.id === peripheral.stationId)
              })
            })
          )
          await Promise.all(promises)
        }
        resolve(peripherals)
      }).catch((error) => {
        state.disconnectedPeripheralService = true
        reject(error)
      })
    })
  },
  ping ({ state, commit }) {
    state.disconnectedPeripheralService = false
    return new Promise((resolve, reject) => {
      peripheralService.listAdopted().catch((error) => {
        commit('state/setMessage', { text: i18n.t('peripherals.warnings.disconnected_peripheral_service'), color: 'error' }, { root: true })
        state.disconnectedPeripheralService = true
        reject(error)
      })
    })
  }
}

const mutations = {
  setRead (state, peripheral) {
    Vue.set(state, 'read', peripheral)
  },
  setList (state, list) {
    Vue.set(state, 'list', list)
  },
  setReader (state, readerPeripheralId) {
    state.selectedReader = readerPeripheralId
    if (readerPeripheralId) {
      localStorage.setItem('selectedReader', readerPeripheralId)
    } else {
      localStorage.removeItem('selectedReader')
    }
  },
  setPrinter (state, printerPeripheralId) {
    state.selectedPrinter = printerPeripheralId
    if (printerPeripheralId) {
      localStorage.setItem('selectedPrinter', printerPeripheralId)
    } else {
      localStorage.removeItem('selectedPrinter')
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
