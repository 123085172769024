import { GuestRequests } from '@/services/guest.requests'
import { PackageRequests } from '@/services/package.requests'
import { Guest } from '@/entities/Guest'
import Vue from 'vue'

const guestService = new GuestRequests()
const packageService = new PackageRequests()

const state = {
  read: null,
  edited: null,
  adjustments: [],
  errors: {}
}

const getters = {
  isUnsaved: state => state.edited && !!Object.keys(state.edited).length,
  getGuestAccountByPriority: (state, getters, rootState, rootGetters) => guest => {
    const hasSomeBalance = rootGetters['accounts/hasSomeBalance']
    if (guest) {
      const { account, roomAccount, booking } = guest
      if (account && hasSomeBalance(account)) {
        return account
      } else if (roomAccount && hasSomeBalance(roomAccount)) {
        return roomAccount
      } else if (booking && booking.account && hasSomeBalance(booking.account)) {
        return booking.account
      } else {
        return account || roomAccount || (booking ? booking.account : null)
      }
    }
    return null
  },
  getPackageAmount: (state) => (packageId) => {
    const packageInCart = state.adjustments.find((adjustment) => adjustment.packageId === packageId)
    return packageInCart ? packageInCart.quantity : 0
  },
  guestPricingGroup: (state) => {
    try { return state.read.extendedAccount.account.pricingGroupId }
    catch { return null }
  }
}

const actions = {
  get ({ commit, dispatch, rootGetters }, { id, decorate, save }) {
    return new Promise((resolve, reject) => {
      guestService.get(id).then((guest) => {
        if (save) {
          commit('setRead', guest)
        }
        var promises = []
        if (decorate) {
          promises.push(dispatch('profiles/get', { save: true, id: guest.profileId, decorate: true }, { root: true }).then((profile) => {
            guest.profile = profile
            if (save) {
              commit('setRead', guest)
            }
          }))
          if (guest.accountId) {
            promises.push(dispatch('accounts/get', { save: false, id: guest.accountId }, { root: true }).then((extendedAccount) => {
              guest.extendedAccount = extendedAccount
              if (save) {
                commit('setRead', guest)
              }
            }))
          }
          if (guest.bookingId) {
            promises.push(dispatch('bookings/get', { save: false, id: guest.bookingId }, { root: true }).then((booking) => {
              guest.booking = booking
              if (save) {
                commit('setRead', guest)
              }
            }))
          }
        }
        Promise.all(promises).then(() => {
          resolve(guest)
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  save ({ state, dispatch, commit }) {
    return new Promise((resolve, reject) => {
      var read = state.read || new Guest()
      guestService.save(read.serialize(state.edited)).then((id) => {
        commit('resetEdited')
        dispatch('get', { id, save: true, decorate: true }).then(() => {
          resolve(id)
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  postAdjustments ({ state, rootGetters }) {
    const positiveAdjustments = state.adjustments.filter(t => t.quantity > 0)
    const negativeAdjustments = state.adjustments.filter(t => t.quantity < 0)
    const guestId = state.read.id
    const stationId = localStorage.getItem('selectedStationId')
    const isSupervisor = rootGetters['shifts/isSupervisor']
    const operatorId = rootGetters['auth/operatorId']
    const promises = []
    if (positiveAdjustments.length) {
      promises.push(
        packageService.postAdjustments({ packages: positiveAdjustments, guestId, stationId, isSupervisor, operatorId })
      )
    }
    if (negativeAdjustments.length) {
      promises.push(
        packageService.postAdjustments({ packages: negativeAdjustments, guestId, stationId, isSupervisor, operatorId })
      )
    }
    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  setRead (state, guest) {
    state.read = guest
  },
  setField (state, { field, value }) {
    if (!state.edited) {
      state.edited = {}
      if (state.read && state.read.id) {
        Vue.set(state.edited, 'id', state.read.id)
      }
    }
    Vue.set(state.edited, field, value)
  },
  resetAdjustments (state) {
    Vue.set(state, 'adjustments', [])
    Vue.set(state, 'errors', {})
  },
  setPackageQuantity (state, { packageId, quantity }) {
    const index = state.adjustments.findIndex(adjustment => adjustment.packageId === packageId)
    if (index === -1) {
      Vue.set(state.adjustments, state.adjustments.length, { packageId, quantity })
    } else {
      if (quantity === 0) {
        const newCart = state.adjustments.splice(index, index + 1)
        Vue.set(state.adjustments, newCart)
      } else {
        Vue.set(state.adjustments, index, { packageId, quantity })
      }
    }
  },
  setError (state, { packageId, text }) {
    Vue.set(state.errors, packageId, [text])
  },
  deleteError (state, packageId) {
    Vue.delete(state.errors, packageId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
