import { PaymentMethodRequests } from '@/services/payment-method.requests'
import { PaymentMethod } from '@/entities/PaymentMethod'
const paymentMethodService = new PaymentMethodRequests()

const state = {
  list: []
}

const actions = {
  list ({ state }) {
    return new Promise((resolve, reject) => {
      paymentMethodService.list().then(async (paymentMethods) => {
        state.list = paymentMethods
        resolve(paymentMethods)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions
}
