import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Facility } from '@/entities/Facility'
const requestService = new RequestService()

export class FacilityRequests {
  get (id) {
    const route = ServerDirections.FACILITIES_ID.replace('{id}', id)
    const method = 'get'
    function map (data) {
      return new Facility(data)
    }
    return requestService.makePromise(route, method, {}, map)
  }
}
