import ServerDirections from '@/config/server-directions'
import { RequestService } from '@/services/request.service'
import { Transaction } from '@/entities/Transaction'
import snakeCaseKeys from 'snakecase-keys'
const requestService = new RequestService()

export class TransactionRequests {
  list (attributes) {
    const { limit, offset, accountIds, currencyIds, filter, order, guestId, stationId, operatorId, currencyType, reference, createdSince, createdUntil, type, paymentMethodIds, linkedToOrder } = attributes
    const route = ServerDirections.TRANSACTIONS
    const method = 'get'
    function map (data) {
      return {
        list: data.data.map(item => {
          return new Transaction(item)
        }),
        meta: {
          hasNext: data.meta.has_next
        }
      }
    }
    var params = {
      limit,
      offset,
      order,
      guestId,
      stationId,
      operatorId,
      currencyType,
      reference,
      createdSince,
      createdUntil
    }
    if (filter) {
      params.filter = filter
    }
    if (type) {
      params.type = type
    }
    if (accountIds) {
      params.account_ids = accountIds.toString() || []
    }
    if (currencyIds) {
      params.currency_ids = currencyIds.toString() || []
    }
    if (paymentMethodIds) {
      params.payment_method_ids = paymentMethodIds.toString() || []
    }
    if (typeof linkedToOrder === 'boolean') {
      params.linked_to_order = linkedToOrder
    }
    if (guestId) {
      params.guest_id = guestId
    }
    return requestService.makePromise(route, method, { params }, map)
  }

  get (id) {
    const route = ServerDirections.TRANSACTIONS_ID.replace('{id}', id)
    const method = 'get'
    function map (data) {
      return new Transaction(data)
    }
    return requestService.makePromise(route, method, { }, map)
  }

  post (transactions) {
    const route = ServerDirections.POS_TRANSACTIONS
    const method = 'post'
    function map (data) {
      return data
    }
    const body = snakeCaseKeys(transactions)
    return requestService.makePromise(route, method, { body }, map)
  }

  getTicket (id) {
    const route = ServerDirections.POS_TRANSACTIONS_ID_TICKET.replace('{id}', id)
    const method = 'get'
    function map (data) {
      var bytes = new Uint8Array(data)
      var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '') // eslint-disable-line
      return btoa(binary)
    }
    return requestService.makePromise(route, method, { responseType: 'arraybuffer' }, map)
  }

  getTicket (id) {
    const route = ServerDirections.POS_TRANSACTIONS_ID_TICKET.replace('{id}', id)
    const method = 'get'
    function map (data) {
      var bytes = new Uint8Array(data)
      var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '') // eslint-disable-line
      return btoa(binary)
    }
    return requestService.makePromise(route, method, { responseType: 'arraybuffer' }, map)
  }

  getTicket (id) {
    const route = ServerDirections.POS_TRANSACTIONS_ID_TICKET.replace('{id}', id)
    const method = 'get'
    function map (data) {
      var bytes = new Uint8Array(data)
      var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '') // eslint-disable-line
      return btoa(binary)
    }
    return requestService.makePromise(route, method, { responseType: 'arraybuffer' }, map)
  }

  getTicket (id) {
    const route = ServerDirections.POS_TRANSACTIONS_ID_TICKET.replace('{id}', id)
    const method = 'get'
    function map (data) {
      var bytes = new Uint8Array(data)
      var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '') // eslint-disable-line
      return btoa(binary)
    }
    return requestService.makePromise(route, method, { responseType: 'arraybuffer' }, map)
  }

  getTicket (id) {
    const route = ServerDirections.POS_TRANSACTIONS_ID_TICKET.replace('{id}', id)
    const method = 'get'
    function map (data) {
      var bytes = new Uint8Array(data)
      var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '') // eslint-disable-line
      return btoa(binary)
    }
    return requestService.makePromise(route, method, { responseType: 'arraybuffer' }, map)
  }
}
