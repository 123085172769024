import { RequestService } from '@/services/request.service'
import { Shift } from '@/entities/Shift'
import ServerDirections from '@/config/server-directions'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import store from '@/store'
const requestService = new RequestService()

export class ShiftRequests {
  getOperatorShift ({ useTemporalToken }) {
    function map (data) {
      return {
        name: data.name,
        role: data.role,
        lastShift: data.last_shift ? new Shift(data.last_shift) : null
      }
    }
    const route = ServerDirections.POS_ME
    const method = 'get'
    return requestService.makePromise(route, method, { useTemporalToken, keepTemporalToken: useTemporalToken }, map)
  }

  getPrinterShift (printerId) {
    function map (data) {
      return new Shift(data)
    }
    const route = ServerDirections.POS_PRINTERS_ID_SHIFTS.replace('{id}', printerId)
    const method = 'get'
    return requestService.makePromise(route, method, { disableStatusHandling: { 404: true } }, map)
  }

  getShiftTicket (shiftId) {
    function map (data) {
      var bytes = new Uint8Array(data)
      var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '') // eslint-disable-line
      return btoa(binary)
    }
    const route = ServerDirections.POS_SHIFTS_ID_TICKET.replace('{id}', shiftId)
    const method = 'get'
    return requestService.makePromise(route, method, { responseType: 'arraybuffer' }, map)
    
  }

  open ({ operatorId, printerId, stationId, accountingState }) {
    function map (data) {
      return data
    }
    const route = ServerDirections.POS_SHIFTS
    const method = 'post'
    const body = snakecaseKeys({
      operator_id: operatorId,
      station_id: stationId,
      printer_id: printerId,
      initial_accounting_state: accountingState
    })
    return requestService.makePromise(route, method, { body, useTemporalToken: !store.getters['shifts/isSupervisor'] }, map)
  }

  close ({ shiftId }) {
    function map (data) {
      return new Shift(data)
    }
    const route = ServerDirections.POS_SHIFTS_ID.replace('{id}', shiftId)
    const method = 'post'
    return requestService.makePromise(route, method, { useTemporalToken: !store.getters['shifts/isSupervisor'] }, map)
  }

  list ({ limit, offset, status, operatorId, printerId, openedAfter, openedBefore, closedAfter, closedBefore }) {
    const route = ServerDirections.POS_SHIFTS
    const method = 'get'
    const params = {
      limit, offset
    }
    if (status) {
      params.status = status
    }
    if (operatorId) {
      params.operator_id = operatorId
    }
    if (printerId) {
      params.printer_id = printerId
    }
    if (openedAfter) {
      params.opened_after = openedAfter
    }
    if (openedBefore) {
      params.opened_before = openedBefore
    }
    if (closedAfter) {
      params.closed_after = closedAfter
    }
    if (closedBefore) {
      params.closed_before = closedBefore
    }
    return requestService.makePromise(route, method, { params }, map)
    function map (data) {
      const list = data.data.map(item => {
        return new Shift(item)
      })
      return {
        list,
        meta: camelcaseKeys(data.meta)
      }
    }
  }
}
