import Vue from 'vue'
import { ShiftRequests } from '@/services/shift.requests'
import { operatorRoles } from '@/config'
const shiftService = new ShiftRequests()
const state = {
  operatorShift: null,
  printerShift: null,
  finishedLoadingShifts: false,
  loaded: {
    printerShift: false,
    operatorShift: false
  },
  accountingState: null,
  list: [],
  read: null
}

const getters = {
  isSupervisor: (state) => {
    return state.operatorShift ? state.operatorShift.role === operatorRoles.SUPERVISOR : false
  },
  operatorShift: (state) =>
    state.operatorShift && state.operatorShift.lastShift
      ? state.operatorShift.lastShift.closedAt
        ? null
        : state.operatorShift.lastShift
      : null
  ,
  printerShift: (state) =>
    state.printerShift && !state.printerShift.closedAt
      ? state.printerShift
      : null
  
}

const actions = {
  open ({ state, rootState, dispatch }) {
    return new Promise((resolve, reject) => {
      shiftService.open({ operatorId: rootState.auth.credentials.operator.id, printerId: localStorage.getItem('selectedPrinter'), stationId: localStorage.getItem('selectedStationId') , accountingState: state.accountingState }).then(() => {
        resolve()
        dispatch('reloadShifts')
      }).catch((error) => {
        reject(error)
      })
    })
  },
  reloadShifts ({ dispatch }) {
    dispatch('getOperatorShift')
      .catch(() => {})
    dispatch('getPrinterShift', { id: localStorage.getItem('selectedPrinter')})
      .catch(() => {})
  },
  getOperatorShift ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setShiftLoaded', { shiftName: 'operatorShift', value: false })
      shiftService.getOperatorShift({}).then(async (operatorShift) => {
        commit('setOperatorShift', operatorShift)
        commit('setShiftLoaded', { shiftName: 'operatorShift', value: true })
        resolve(operatorShift)
      }).catch((error) => {
        commit('setOperatorShift', null)
        if (error.status === 404) {
          commit('setShiftLoaded', { shiftName: 'operatorShift', value: true })
        }
        reject(error)
      })
    })
  },
  getPrinterShift ({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      commit('setShiftLoaded', { shiftName: 'printerShift', value: false })
      shiftService.getPrinterShift(id).then(async (printerShift) => {
        commit('setPrinterShift', printerShift)
        commit('setShiftLoaded', { shiftName: 'printerShift', value: true })
        resolve(printerShift)
      }).catch((error) => {
        commit('setPrinterShift', null)
        if (error.status === 404) {
          commit('setShiftLoaded', { shiftName: 'printerShift', value: true })
        }
        reject(error)
      })
    })
  },
  list ({ commit }, { limit, offset, order, sort, status, operatorId, printerId, openedAfter, openedBefore, closedAfter, closedBefore }) {
    return new Promise((resolve, reject) => {
      shiftService.list({ limit, offset, status, operatorId, printerId, openedAfter, openedBefore, closedAfter, closedBefore }).then(async (res) => {
        commit('setList', res.list)
        resolve(res)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  decorateRead ({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Promise.all([
        dispatch('stations/list', {}, { root: true }),
        dispatch('peripherals/list', {}, { root: true }),
        dispatch('operators/list', {}, { root: true })
      ]).then((res) => {
        const stations = res[0]
        const peripherals = res[1]
        const operators = res[2]
        const station = stations.find((station) => station.id === state.read.stationId)
        const printer = peripherals.find((peripheral) => peripheral.id === state.read.printerId)
        const openedByOperator = operators.find((operator) => operator.id === state.read.openedByOperatorId)
        const closedByOperator = operators.find((operator) => operator.id === state.read.closedByOperatorId)
        const operator = operators.find((operator) => operator.id === state.read.operatorId)
        const read = {
          ...state.read,
          station,
          printer,
          openedByOperator,
          closedByOperator,
          operator
        }
        commit('setRead', read)
        resolve()
      }).catch((err) => {
        reject(err)
      })
    })
  },
  decorateColumn ({ dispatch }, path) {
    switch (path) {
      case 'operator.name':
        dispatch('decorateOperator')
        break
      case 'station.name':
        dispatch('decorateStation')
        break
      case 'device':
        dispatch('decorateDevice')
        break
      case 'openedByOperator.name':
        dispatch('decorateOpenedByOperator')
        break
      case 'closedByOperator.name':
        dispatch('decorateClosedByOperator')
        break
    }
  },
  decorateOperator ({ state, commit, dispatch }) {
    dispatch('operators/list', {}, { root: true }).then((operators) => {
      const list = state.list.map((shift) => {
        const operator = operators.find((operator) => operator.id === shift.operatorId)
        return { ...shift, operator }
      })
      commit('setList', list)
    })
  },
  decorateStation ({ state, commit, dispatch }) {
    dispatch('stations/list', {}, { root: true }).then((stations) => {
      const list = state.list.map((shift) => {
        const station = stations.find((station) => station.id === shift.stationId)
        return { ...shift, station }
      })
      commit('setList', list)
    })
  },
  decorateDevice ({ state, commit, dispatch }) {
    dispatch('peripherals/listAll', {}, { root: true }).then((peripherals) => {
      const list = state.list.map((shift) => {
        const printer = peripherals.find((peripheral) => peripheral.id === shift.printerId)
        return { ...shift, device: printer }
      })
      commit('setList', list)
    })
  },
  decorateOpenedByOperator ({ state, commit, dispatch }) {
    dispatch('operators/list', {}, { root: true }).then((operators) => {
      const list = state.list.map((shift) => {
        const operator = operators.find((operator) => operator.id === shift.openedByOperatorId)
        return { ...shift, openedByOperator: operator }
      })
      commit('setList', list)
    })
  },
  decorateClosedByOperator ({ state, commit, dispatch }) {
    dispatch('operators/list', {}, { root: true }).then((operators) => {
      const list = state.list.map((shift) => {
        const operator = operators.find((operator) => operator.id === shift.closedByOperatorId)
        return { ...shift, closedByOperator: operator }
      })
      commit('setList', list)
    })
  },
  resetAccountingState ({ state, rootGetters }) {
    const initialAccountingState = []
    rootGetters['configuration/paymentMethodIds'].forEach((paymentMethodId) => {
      rootGetters['configuration/currencies'].forEach((currency) => {
        initialAccountingState.push({ amount: 0, currencyId: currency.id, paymentMethodId })
      })
    })
    Vue.set(state, 'accountingState', initialAccountingState)
  }
}

const mutations = {
  setShiftLoaded: (state, { shiftName, value }) => {
    Vue.set(state.loaded, shiftName, value)
    if (!value) {
      state.finishedLoadingShifts = false
    } else if (!localStorage.getItem('selectedPrinter')) {
      state.finishedLoadingShifts = true
    } else if (Object.keys(state.loaded).every((k) => state.loaded[k])) {
      state.finishedLoadingShifts = true
    }
  },
  setOperatorShift: (state, operatorShift) => {
    Vue.set(state, 'operatorShift', operatorShift)
  },
  setPrinterShift: (state, printerShift) => {
    Vue.set(state, 'printerShift', printerShift)
  },
  setAccountingState (state, { amount, currencyId, paymentMethodId }) {
    if (!state.accountingState) {
      state.accountingState = []
      state.accountingState.push({ amount, currencyId, paymentMethodId })
    } else {
      var foundIndex = state.accountingState.findIndex((a) => { return a.paymentMethodId === paymentMethodId && a.currencyId === currencyId })
      if (foundIndex > -1) {
        state.accountingState.splice(foundIndex, 1)
      }
      state.accountingState.push({ amount, currencyId, paymentMethodId })
    }
  },
  setList (state, list) {
    Vue.set(state, 'list', list)
  },
  setRead (state, shift) {
    Vue.set(state, 'read', shift)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
