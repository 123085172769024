import { i18n } from '@/locale'
import store from '@/store'
import dayjs from 'dayjs'

function getGuestFullName (guest, noDefault) {
  const firstName = getGuestName(guest, noDefault)
  const lastName = getGuestLastName(guest)
  return lastName
    ? `${firstName} ${lastName}`
    : `${firstName}`
}
function getGuestName (guest, noDefault) {
  if (!guest.profile) {
    return noDefault ? '' : i18n.t('default_content.no_name')
  }
  return guest.profile.name
    ? guest.profile.name
    : noDefault
      ? ''
      : i18n.t('default_content.no_name')
}
function getGuestLastName (guest) {
  if (!guest.profile) {
    return ''
  }
  return guest.profile.lastName
    ? guest.profile.lastName
    : ''
}

function isGuestDisabled (guest) {
  return guest && guest.checkoutMoment ? dayjs(guest.checkoutMoment).isBefore(dayjs()) : false
}

function postpaidBaseCurrency (guest) {
  try {
    return guest.extendedAccount.currencies.find(c => c.currency.id === store.getters['configuration/localCurrency'].id).postpaid
  } catch {
    return false
  }
}

function getTokensInAccount (currencies, pkg) {
  try {
    return currencies
      .find(c => c.currency.id === pkg.tokenCurrencyId)
      .balance
  } catch {
    return null
  }
}

function findWristbandExternalId (guest) {
  return guest.externalIds.find(externalId => {
    return externalId.qualifier === 'RFID_KEY'
  })
}

function isHotelGuest (extendedGuest) {
  const facilityId = extendedGuest && extendedGuest.booking ? extendedGuest.booking.facilityId : null
  return store.getters['configuration/hotelFacilityId'] === facilityId
}

export {
  getGuestFullName,
  getGuestName,
  getGuestLastName,
  isGuestDisabled,
  getTokensInAccount,
  findWristbandExternalId,
  isHotelGuest,
  postpaidBaseCurrency
}
