export default {
  refund_requests: {
    name: 'refund_requests',
    icon: 'mdi-hand-heart'
  },
  balance_adjustment: {
    name: 'balance_adjustment'
  },
  package_order: {
    name: 'package_order'
  },
  refund: {
    name: 'refund',
    icon: 'mdi-undo'
  },
  guest: {
    name: 'guest'
  },
  ticket: {
    name: 'ticket'
  },
  shifts: {
    name: 'shifts',
    icon: 'mdi-history'
  },
  settings: {
    name: 'settings',
    icon: 'mdi-cog'
  },
  ticket_order: {
    name: 'ticket_order',
    icon: 'mdi-ticket'
  },
  ticket_validation: {
    name: 'ticket_validation',
    icon: 'mdi-clipboard-check'
  },
  login: {
    name: 'login',
    hidden_menu: true
  },
  error: {
    name: 'error',
    hidden_menu: true
  }
}
