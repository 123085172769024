import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import VueResource from 'vue-resource'
import pjson from '@/../package.json'

import { i18n } from './locale'
import { ErrorManager } from './services/error.service'

Vue.config.productionTip = false

const errorManager = new ErrorManager()

Bugsnag.start({
  apiKey: 'dff6ab7bd2a43263a01e785ea2d446bf',
  plugins: [new BugsnagPluginVue()],
  appVersion: pjson.version
})

Vue.use(VueResource)

import dayjs from 'dayjs'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import objectSupport from 'dayjs/plugin/objectSupport'
import utc from 'dayjs/plugin/utc'
dayjs.extend(isBetweenPlugin)
dayjs.extend(objectSupport)
dayjs.extend(utc)

Vue.http.interceptor.before = function (request, next) {
  store.commit('state/setLoading', true)
  next(response => {
    if (!response.ok) {
      errorManager.manageError(response, request.disableStatusHandling)
    }
    store.commit('state/setLoading', false)
  })
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
