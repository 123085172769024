import { FacilityRequests } from '@/services/facility.requests'
const facilityService = new FacilityRequests()

const state = {
  read: null
}

const actions = {
  get ({ state }, { id }) {
    return new Promise((resolve, reject) => {
      facilityService.get(id).then((facility) => {
        state.read = facility
        resolve(state.read)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions
}
