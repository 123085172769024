import Vue from 'vue'
import { initialSortFieldsByTable } from '@/config'
const state = {
  itemsPerPageOptions: [
    10,
    25,
    50
  ],
  tables: {}
}

const actions = {
  filterListRequest ({ state, commit, dispatch }, { table }) {
    state.tables[table].pages.totalItems = null
    state.tables[table].pages.reachedEnd = false
    state.tables[table].pages.totalPages = 1
    if (state.tables[table].pages.page !== 1) {
      commit('setToFirstPage', { table })
    } else {
      dispatch('listRequest', { table })
    }
  },
  listRequest ({ state }, { table }) {
    if (state.tables[table].listMethod) {
      state.tables[table].listMethod()
    }
  }
}

const mutations = {
  setItemsPerPageOptions (state, { table, itemsPerPageOptions }) {
    state.tables[table].itemsPerPageOptions = itemsPerPageOptions
    state.tables[table].pages.itemsPerPage = itemsPerPageOptions[0]
  },
  set (state, { table, newPag }) {
    var temp = JSON.parse(JSON.stringify(state.tables[table].pages))
    temp.sortBy = newPag.sortBy
    temp.descending = newPag.descending
    state.tables[table].pages = JSON.parse(JSON.stringify(temp))
  },
  decrease (state, { table }) {
    var temp = JSON.parse(JSON.stringify(state.tables[table].pages))
    temp.page -= 1
    state.tables[table].pages = JSON.parse(JSON.stringify(temp))
  },
  increase (state, { table }) {
    var temp = JSON.parse(JSON.stringify(state.tables[table].pages))
    if (state.tables[table].pages.totalPages === state.tables[table].pages.page) {
      temp.totalPages += 1
    }
    temp.page += 1
    state.tables[table].pages = JSON.parse(JSON.stringify(temp))
  },
  update (state, { table, length, hasNext }) {
    var temp = state.tables[table].pages
    temp.hasNext = hasNext
    if (!hasNext) {
      temp.totalItems = length
      temp.reachedEnd = true
    }
    Vue.set(state.tables[table], 'pages', temp)
  },
  setLoading (state, { table, loading }) {
    Vue.set(state.tables[table], 'loading', loading)
  },
  setToFirstPage (state, { table }) {
    Vue.set(state.tables[table].pages, 'page', 1)
  },
  initializeTable (state, { table, listMethod }) {
    Vue.set(state.tables, table, {
      pages: {
        totalItems: null,
        totalPages: 1,
        hasNext: false,
        page: 1,
        itemsPerPage: 25,
        sortBy: initialSortFieldsByTable[table] || null,
        descending: false,
        reachedEnd: false
      },
      listMethod,
      loading: false
    })
  },
  reset (state, { table }) {
    state.tables[table].itemsPerPageOptions = [
      25,
      50
    ]
    var t = state.tables[table]
    if (t) {
      Vue.set(state.tables[table], 'pages', {
        totalItems: null,
        totalPages: 1,
        hasNext: false,
        page: 1,
        itemsPerPage: 25,
        sortBy: null,
        descending: false,
        reachedEnd: false
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
